<template>
  <div :class="{'bg': isHome}">
    <div :class="{'footer-home': isHome, 'footer': !isHome}" class="footer text-center">
      &copy; 2023 by TAC ECONOMICS.
    </div>
    <br />
  </div>
</template>

<script>
export default {
  computed: {
    isHome() {
      return this.$route.path === "/";
    }
  }
};
</script>

<style scoped>
.footer {
  font-size: 0.8rem;
  color: #6e6e6e;
}

.footer-home {
  font-size: 0.8rem;
  color: #ffffff;
}

.bg {
  background-color: #000b36;
}
</style>
