<template>
      <h3 class="pt-3"><i class="fa-solid fa-book-atlas me-3"></i>Geopolitical Sensitivity Index</h3>
      <hr />
      <div class="mt-3 position-relative d-flex align-items-center bg-light pt-2 pb-2 mb-4" style="border-radius: 5px;">
          <div
              class="sliding-bg"
              :style="{ width: bgWidth, transform: `translateX(${bgPosition}px)` }"
              :class="['p-1']"
          ></div>
  
          <p
              v-for="(item, index) in items"
              :key="index"
              @click="selectItem(index, $event, item.text)"
              :class="['ps-3', 'pe-3', 'mb-0', 'item', 'd-flex', 'align-items-center', 'justify-content-center']"
              :style="{ color: selectedItem === index ? 'black' : 'gray', width: '100%' }"
              style="font-size: small;"
          >
              <i :class="item.icon" class="me-2"></i>
              {{ item.text }}
          </p>
      </div>

      <!--<div class="d-flex" style="height: 100%;">
        <div class="border bg-white" style="width: 100%">
          <div id="chartdiv" style="width: 100%; height: 500px;"></div>
        </div>
      </div> -->
  
      <div class="d-flex" style="height: 100%;">
        <div class="border bg-white" :style="{ width: isOpen ? '50%' : '100%' }">
          <div id="chartdiv" style="width: 100%; height: 500px;"></div>
        </div>
        <div class="ms-2 border expandable" :class="{ open: isOpen }" style="width: 50%;">
          <div v-if="isOpen">
          <div class="d-flex" style="justify-content: end;">
              <div class="d-flex" style="justify-content: end;">
                <p class="border p-2 m-1" style="border-radius: 5px; cursor: pointer;" @click="toggleOpen">Close</p>
              </div>
          </div>
          <div style="max-height: 500px;">
            <RadarGeoRisk :selectedgeopol="selectedCountry" />
          </div>
          </div>
        </div>
      </div>
  
          <h2 class="pt-5">{{ ItemSelected }} Country Ranking </h2>
      <hr />
  
      <div class="table-responsive" style="max-height: 500px; overflow-y: auto;">
        <table class="table custom-table mt-4" style="table-layout: fixed; width: 100%;">
            <thead class="sticky-header">
                <tr>
                    <th v-for="header in headers" :key="header.text" @click="header.sortable ? sortByCriteria(header.text): null" 
                        :style="header.sortable ? { cursor: 'pointer' } : null" 
                        :class="header.text === ItemSelected ? 'selected-header' : 'dimmed-header'"
                        class="text-center" style="width: 16.66%;">
                        <div style="display: block; text-align: center;">
                            <i :class="header.icon" class="me-2 ms-2 text-center"></i>
                            <div class="mt-3">
                                {{ header.text }}
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody style="height: 400px; overflow-y: auto; display: table-row-group;">
                <tr class="items" v-for="(item, index) in Top20Countries" :key="index">
                    <td class="text-center">{{ item.rank }}</td>
                    <td class="text-center">{{ item.country }}</td>
                    <td class="text-center">
                        <div class="d-flex align-items-center">
                            <span :class="ItemSelected === 'Geopolitical Sensitivity Index' ? 'highlighted-value' : 'dimmed-value'" class="me-2">{{ item.gpsi }}</span>
                            <div class="progress" style="width: 100%;">
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    :style="{ width: item.gpsi + '%', backgroundColor: getProgressColor(item.gpsi) }"
                                    :aria-valuenow="item.gpsi"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="d-flex align-items-center">
                            <span :class="ItemSelected === 'Alliances' ? 'highlighted-value' : 'dimmed-value'" class="me-2">{{ item.alliances }}</span>
                            <div class="progress" style="width: 100%;">
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    :style="{ width: item.alliances + '%', backgroundColor: getProgressColor(item.alliances) }"
                                    :aria-valuenow="item.alliances"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="d-flex align-items-center">
                            <span :class="ItemSelected === 'Current Conflicts & Sanctions' ? 'highlighted-value' : 'dimmed-value'" class="me-2">{{ item.currentConflicts }}</span>
                            <div class="progress" style="width: 100%;">
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    :style="{ width: item.currentConflicts + '%', backgroundColor: getProgressColor(item.currentConflicts) }"
                                    :aria-valuenow="item.currentConflicts"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="d-flex align-items-center">
                            <span :class="ItemSelected === 'Critical Locks & Knots' ? 'highlighted-value' : 'dimmed-value'" class="me-2">{{ item.criticalLocks }}</span>
                            <div class="progress" style="width: 100%;">
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    :style="{ width: item.criticalLocks + '%', backgroundColor: getProgressColor(item.criticalLocks) }"
                                    :aria-valuenow="item.criticalLocks"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
      <div class="d-flex justify-content-between">
        <p style="font-size: small;">{{ ItemSelected }} from 0 (very low sensitivity) to 100 (very high sensitivity)</p>
        <p style="font-size: small;">Source: TAC ECONOMICS</p>
      </div>
  </template>
  
  <script setup>
  /* eslint-disable */
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4maps from "@amcharts/amcharts4/maps";
  import TacEconomics from "../assets/img/taclogo.png";
  import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
  import { onMounted, ref } from "vue";
  import { countryNames, mapIso2ToIso3 } from "../utils/CountryMapping";
  import RadarGeoRisk from "./RadarGeoRisk.vue";

  const items = ref([
    { text: "Geopolitical Sensitivity Index", icon: "fa-solid fa-book-atlas fa-lg" },
    { text: "Alliances", icon: "fa-solid fa-handshake fa-lg" },
    { text: "Current Conflicts & Sanctions", icon: "fa-solid fa-triangle-exclamation fa-lg" },
    { text: "Critical Locks & Knots", icon: "fa-brands fa-joomla fa-lg" }
  ]);
  
  const ItemSelected = ref("Geopolitical Sensitivity Index");
  const polygonSeries = ref(null);
  
  function getProgressColor(value) {
    if (value >= 60) {
      return '#a82418';
    } else if (value >= 50) {
      return '#f2cacb';
    } else if (value >= 40) {
      return '#faf1d2';
    } else if (value >= 30) {
      return '#85cdf7';
    } else {
      return '#004085';
    }
  }
  
  const headers = ref([
    { text: "Rank", icon: "fa-solid fa-sort-numeric-up fa-xl" },
    { text: "Country", icon: "fa-solid fa-flag fa-xl" },
    { text: "Geopolitical Sensitivity Index", icon: "fa-solid fa-book-atlas fa-xl", sortable: true },
    { text: "Alliances", icon: "fa-solid fa-handshake fa-xl", sortable: true },
    { text: "Current Conflicts & Sanctions", icon: "fa-solid fa-triangle-exclamation fa-xl", sortable: true },
    { text: "Critical Locks & Knots", icon: "fa-brands fa-joomla fa-xl", sortable: true }
  ]);
  
  const selectedItem = ref(0);
  const bgPosition = ref(0);
  const bgWidth = ref('0px');
  const isOpen = ref(false);

  const sortByCriteria = (criteria) => {
    if (criteria === "Geopolitical Sensitivity Index") {
      getCountriesSorted("GPSI");
      ItemSelected.value = "Geopolitical Sensitivity Index";
      return;
    }
    getCountriesSorted(criteria);
    ItemSelected.value = criteria;
  }
  
  const toggleOpen = () => {
    isOpen.value = false;
  }

  const selectItem = (index, event, text) => {
    if (selectedItem.value !== index) {
      const targetElement = event.target;
      bgWidth.value = `${targetElement.offsetWidth}px`;
      bgPosition.value = targetElement.offsetLeft;
      selectedItem.value = index;
    }
    ItemSelected.value = text;
    if (ItemSelected.value == "Geopolitical Sensitivity Index")
      text = "GPSI";
  
    fetchData().then(() => updateMap(text.toLowerCase()));
      getCountriesSorted(text);
  };
  
  const fetchedData = ref([]);
  const Top20Countries = ref([]);
  const selectedCountry = ref(null);
  
  async function fetchData() {
    try {
      const response = await fetch("https://api.taceconomics.com/data/loadviews/geopol/ALLCNT?api_key=sk_TC1nshtfPBJdI51dGCo7DDgfo0s8o1qRVkaQ_IR2r1w");
      const data = await response.json();
      fetchedData.value = data.data;
    } catch (error) {
      console.error(error);
    }
  }
  
  function updateMap(selectedItem) {
    if (fetchedData.value.length > 0) {
  
          let dataToDisplay;
          if (selectedItem === "current conflicts & sanctions") {
                  dataToDisplay = fetchedData.value.map(country => {
                  return {
                      iso2: country.iso2,
                      value: country["cur_conf_sanct"]
                  };
              });
          } else if (selectedItem === "critical locks & knots") {
                  dataToDisplay = fetchedData.value.map(country => {
                  return {
                      iso2: country.iso2,
                      value: country["crit_lock_knots"]
                  };
              });
        } else if (selectedItem === "geopolitical sensitivity index") {
                  dataToDisplay = fetchedData.value.map(country => {
                  return {
                      iso2: country.iso2,
                      value: country["gpsi"]
                  };
              });
        } else {
                  dataToDisplay = fetchedData.value.map(country => {
                      return {
                          iso2: country.iso2,
                          value: country[selectedItem]
                      };
                  });
          }
  
      dataToDisplay.forEach(data => {
        const countryPolygon = polygonSeries.value.getPolygonById(data.iso2);
        if (countryPolygon) {
          countryPolygon.fill = getProgressColor(data.value);
          countryPolygon.tooltipText = `${countryNames[data.iso2]} (${mapIso2ToIso3[data.iso2]}) : ${data.value.toPrecision(3)}`;
        }
      });
    } else {
      console.warn("Aucune donnée à afficher sur la carte.");
    }
  }
  
  const sortCriteria = {
    "GPSI": "gpsi",
    "Alliances": "alliances",
    "Critical Locks & Knots": "crit_lock_knots",
    "Current Conflicts & Sanctions": "cur_conf_sanct"
  };
  
  function getCountriesSorted(item) {
  
    const criterion = sortCriteria[item];
    if (!criterion)
          return
    
    Top20Countries.value = fetchedData.value
      .sort((a, b) => b[criterion] - a[criterion])
      .map((country, index) => {
        return {
          rank: index + 1,
          country: countryNames[country.iso2] || country.iso2,
          gpsi: country.gpsi.toPrecision(3),
          alliances: country.alliances.toPrecision(3),
          currentConflicts: country.cur_conf_sanct.toPrecision(3),
          criticalLocks: country.crit_lock_knots.toPrecision(3)
        };
      });
  }
  
  onMounted(async () => {
    let chart = am4core.create("chartdiv", am4maps.MapChart);
    chart.geodata = am4geodata_worldLow;
    chart.projection = new am4maps.projections.Miller();

    polygonSeries.value = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.value.useGeodata = true;
    polygonSeries.value.exclude = ["AQ"];
    polygonSeries.value.mapPolygons.template.tooltipText = "{name}: {value}";

    polygonSeries.value.mapPolygons.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    polygonSeries.value.mapPolygons.template.events.on("hit", function(ev) {
        const isoCode = ev.target.dataItem.dataContext.id;
        selectedCountry.value = mapIso2ToIso3[isoCode];
        isOpen.value = true;
    });

    const imageUrl = TacEconomics
    const image = chart.createChild(am4core.Image);
    image.href = imageUrl;
    image.width = 50;
    image.height = 50;

    image.dx = 20;
    image.dy = 20;

    const latitude = 20;
    const longitude = 30;

    const point = chart.projection.rotate({ longitude: longitude, latitude: latitude });
    image.left = point.x;
    image.top = point.y;

    var legend = new am4maps.Legend();
    legend.parent = chart.chartContainer;
    legend.background.fillOpacity = 0.05;
    legend.width = 275;

    legend.align = "left";
    legend.valign = "bottom";

    legend.dx = 10;
    legend.dy = 350;
  
    legend.layout = "vertical";

    legend.itemContainers.template.marginTop = 5;
    legend.itemContainers.template.marginBottom = 5;

    legend.labels.template.fontSize = "12px";
    legend.labels.template.fill = am4core.color("#666666");

    legend.padding(10, 15, chart.chartContainer.maxHeight, 15);
    legend.data = [{
      "name": "Very Low Sensitivity",
      "fill":"#004085"
    }, {
      "name": "Low Sensitivity",
      "fill":"#85cdf7"
    }, {
      "name": "Medium Sensitivity",
      "fill":"#faf1d2"
    }, {
      "name": "High Sensitivity",
      "fill": "#f2cacb"
    }, {
      "name": "Very High Sensitivity",
      "fill": "#a82418"
    }];
    legend.data.reverse();
    legend.itemContainers.template.clickable = false;
    legend.itemContainers.template.focusable = false;

    legend.itemContainers.template.padding(-9, 0, 0, 0);

    const defaultItem = items.value[0];
    bgWidth.value = `25%`;
    bgPosition.value = 0;

    await fetchData();
    updateMap(defaultItem.text.toLowerCase());
    getCountriesSorted("GPSI");

    if (fetchedData.value.length > 0) {
      chart.exporting.adapter.add("data", function(data) {
        data.data = [];
        for (let row of fetchedData.value) {
          if (ItemSelected.value === "Geopolitical Sensitivity Index")
            data.data.push({
              name: countryNames[row.iso2],
              value: row.gpsi
            });
          else if (ItemSelected.value === "Alliances")
            data.data.push({
              name: countryNames[row.iso2],
              value: row.alliances
            });
          else if (ItemSelected.value === "Current Conflicts & Sanctions")
            data.data.push({
              name: countryNames[row.iso2],
              value: row.cur_conf_sanct
            });
          else if (ItemSelected.value === "Critical Locks & Knots")
            data.data.push({
              name: countryNames[row.iso2],
              value: row.crit_lock_knots
            });
        }
        return data;
      });

      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.menu.align = "right";
      chart.exporting.menu.verticalAlign = "top";

      chart.exporting.menu.items = [{
        "label": "...",
        "menu": [
          { "type": "png", "label": "PNG" },
          { "type": "csv", "label": "CSV" }
        ]
      }];
    }
});
  </script>
    
  <style scoped>

.expandable {
  transition: max-width 2s ease;
  overflow: hidden;
  max-width: 0;
  opacity: 0;
}

.expandable.open {
  max-width: 100%;
  opacity: 1;
}

.expandable.close {
  max-width: 0;
  opacity: 0;
}

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
      background: #f3f3f3;
  }

  ::-webkit-scrollbar-thumb {
      background: #979797;
      border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
      background: #555;
  }

  .amcharts-amexport-item {
    border: 2px solid #777 !important;
  }

  .amcharts-amexport-top .amcharts-amexport-item > .amcharts-amexport-menu {
    top: -3px!important;
    left: 2px
  }
  
  .blocProducts {
    margin-top: 20px;
    padding: 0 7%;
  }
  
  .item {
    cursor: pointer;
    z-index: 1;
    position: relative;
    transition: color 0.3s ease-in-out;
  }
  
  .sliding-bg {
    position: absolute;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid lightgray;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    transition: transform 0.5s ease, width 0.3s ease;
    z-index: 0;
  }
  
  .custom-table {
    min-width: 900px;
      border-collapse: collapse; 
  }
  .custom-table thead tr, .custom-table thead th {
    border-top: none;
    border-bottom: none !important;
  }
  
  .custom-table tbody th, .custom-table tbody td {
    color: #777;
    font-weight: 400;
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 300;
  }
    
  .custom-table tbody th small, .custom-table tbody td small {
    color: #b3b3b3;
    font-weight: 300;
  }
  
  .custom-table tbody .persons {
    padding: 0;
    margin: 0;
  }
      
  .custom-table tbody .persons li {
    padding: 0;
    margin: 0 0 0 -15px;
    list-style: none;
    display: inline-block;
  }
  
  .custom-table tbody .persons li a {
    display: inline-block;
    width: 36px;
  }
  
  .custom-table tbody .persons li a img {
    border-radius: 50%;
    max-width: 100%;
  }
  
  .custom-table tbody tr th, .custom-table tbody tr td {
    position: relative;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
  }
    
  .custom-table tbody tr th:before, .custom-table tbody tr th:after, .custom-table tbody tr td:before, .custom-table tbody tr td:after {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
      content: "";
      left: 0;
      right: 0;
      position: absolute;
      height: 1px;
      background: #bfbfbf;
      width: 100%;
      opacity: 0;
      visibility: hidden;
  }
  
  .custom-table tbody tr th:before, .custom-table tbody tr td:before {
      top: -1px;
  }
  
  .custom-table tbody tr th:after, .custom-table tbody tr td:after {
      bottom: -1px;
  }
  
  .custom-table tbody tr:hover th, .custom-table tbody tr:hover td {
    background: rgba(0, 0, 0, 0.03);
  }
  
  .custom-table tbody tr:hover th:before, .custom-table tbody tr:hover th:after, .custom-table tbody tr:hover td:before, .custom-table tbody tr:hover td:after { 
      opacity: 1;
    visibility: visible;
  }
  
  .custom-table tbody tr.active th, .custom-table tbody tr.active td {
    background: rgba(0, 0, 0, 0.03);
  }
  
  .custom-table tbody tr.active th:before, .custom-table tbody tr.active th:after, .custom-table tbody tr.active td:before, .custom-table tbody tr.active td:after {    
      opacity: 1;
    visibility: visible;
  }
  
  .selected-header {
    color: rgb(0, 0, 0);
      font-size: 12px;
  }
  
  .dimmed-header {
    color: rgb(150, 150, 150);
      font-size: 12px;
  }
  
  .highlighted-value {
    font-weight: bold;
    color: black;
  }
  
  .dimmed-value {
    color: rgb(168, 168, 168);
  }
  
  .name-column {
    width: 300px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .progress {
    height: 0.7rem;
  }
  
  .headers {
    font-size: 12px;
  }
  
  .items {
      font-size: 11px;
  }

  .table-responsive {
    position: relative;
  }

  .sticky-header {
      position: sticky;
      top: 0;
      z-index: 10; 
      background-color: white;
  }

  tbody {
      display: block;
      overflow-y: auto;
      height: 400px;
      width: 100%;
  }

  tr {
      display: table;
      width: 100%;
      table-layout: fixed;
  }
    
  #chartdiv {
      width: 100%;
    height: 500px;
  }
  </style>
    