<template>
    <div class="blocProducts">
        <h3 class="pt-4">Economic and Political Risk Ratings</h3>
        <hr />
        <p class="ps-1 simple-text">From A (0 = lowest risk) to D (100 = highest risk)</p>
    <div class="table-responsive" style="max-height: 70vh; overflow-y: auto;">
        <table class="table custom-table mt-4" style="table-layout: fixed; width: 100%;">
            <thead class="sticky-header">
                <tr>
                    <th v-for="header in headers" :key="header.text" @click="sortTable(header)" style="cursor: pointer;">
                        {{ header.text }}
                    </th>
                </tr>
            </thead>
            <tbody style="height: 400px; overflow-y: auto; display: table-row-group;">
                <tr class="items" v-for="(item, index) in fetchedData" :key="index">
                    <td class="name-header">{{ item.cntName }}</td>
                    <td>{{ item.crp.toPrecision(4) }}</td>
                    <td>{{ item.ratingeco }}</td>
                    <td>{{ item.ratingpol }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const sortBy = ref(true);
const fetchedData = ref([]);
const headers = [
    { text: "Country", value: "cntName" },
    { text: "Country Risk Premium", value: "crp" },
    { text: "Economic Risk Rating", value: "ratingeco" },
    { text: "Political Risk Rating", value: "ratingpol" },
];

async function fetchData() {
    try {
      const response = await fetch("https://api.taceconomics.com/data/loadviews/countryrisk/ALLCNT?api_key=sk_FHbTPUURtauQc0h_rD4SXdkTlNwWSrjlxGLUXqX5hfQ");
      const data = await response.json();
      fetchedData.value = data.data.tab_cnt;
      fetchedData.value.sort((a, b) => a.cntName.localeCompare(b.cntName));
      console.log(fetchedData.value);
    } catch (error) {
      console.error(error);
    }
  }

const sortTable = (header) => {
    sortBy.value = !sortBy.value;
    if (sortBy.value) {
        fetchedData.value.sort((a, b) => {
            if (header.text === "Country") {
                return a.cntName.localeCompare(b.cntName);
            } else if (header.text === "Country Risk Premium") {
                return b[header.value] - a[header.value];
            } else {
                if (header.text === "Economic Risk Rating" || header.text === "Political Risk Rating") {
                    if (a[header.value] == null || b[header.value] == null)
                        return;
                    const getValue = (item) => {
                        const match = item[header.value].match(/\(([^)]+)\)/);
                        return match ? parseFloat(match[1]) : 0;
                    };
                    return getValue(b) - getValue(a);
                }
            }
        });
    } else {
        fetchedData.value.sort((a, b) => {
            if (header.text === "Country") {
                return b.cntName.localeCompare(a.cntName);
            } else if (header.text === "Country Risk Premium") {
                return a[header.value] - b[header.value];
            } else {
                if (header.text === "Economic Risk Rating" || header.text === "Political Risk Rating") {
                    if (a[header.value] == null || b[header.value] == null)
                        return;
                    const getValue = (item) => {
                        const match = item[header.value].match(/\(([^)]+)\)/);
                        return match ? parseFloat(match[1]) : 0;
                    };
                    return getValue(a) - getValue(b);
                }
            }
        });
    }
};

onMounted(() => {
    fetchData();
});
</script>

<style scoped>

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
    background: #f3f3f3;
}

::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.simple-text {
    font-size: 14px;
    color: #6c757d;
}

.name-header {
    color: rgb(197, 0, 0);
}

.table-responsive {
    position: relative;
  }
</style>