<template>
  <div class="blocProducts">
    <div class="tabs">
      <p
        :class="{ active: activeTab === 'tab1' }"
        @click="setActive('tab1')"
      >
        Geopolitical Sensitivity Index
      </p>
      <p
        :class="{ active: activeTab === 'tab2' }"
        @click="setActive('tab2')"
      >
        Alert on GeoPolitical Tensions
      </p>
    </div>
    <div v-if="activeTab == 'tab1'">
      <GeopoliticalSensitivity />
    </div>
    <div v-if="countries.length != 0 && activeTab != 'tab1'">
      <GeopoliticalAlert :data="countries"/>
    </div>
  </div>
</template>

<script setup>
import GeopoliticalSensitivity from '../components/GeopoliticalSensitivity.vue';
import GeopoliticalAlert from '../components/GeopoliticalAlert.vue';
import { onMounted, ref }  from 'vue';
import { countryNames } from '../utils/CountryMapping';

const activeTab = ref('tab1');
const countries = ref([]);

async function fetchCountries() {
    try {
      const response = await fetch("https://api.taceconomics.com/data/loadviews/geopol/ALLCNT?api_key=sk_TC1nshtfPBJdI51dGCo7DDgfo0s8o1qRVkaQ_IR2r1w");
      const data = await response.json();
      countries.value = data.data.map((country) => {
        return {
          code: country.iso2,
          code3: country.country_id,
          country: countryNames[country.iso2],
        };
      });
      countries.value.unshift({
        code: "wd",
        code3: "WLD",
        country: "world",
      });
    } catch (error) {
      console.error(error);
    }
  }

const setActive = (tab) => {
  activeTab.value = tab;
};

onMounted(() => {
  fetchCountries();
});
</script>
  
<style scoped>

.tabs {
  display: flex;
  width: 100%;
}

.tabs p {
  flex-grow: 1;
  text-align: center;
  position: relative;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgba(196, 196, 196, 0.258);
  transition: color 0.3s;
}

.tabs p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(0, 134, 218);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.tabs p.active {
  color: rgb(0, 134, 218);
}

.tabs p.active::after {
  transform: scaleX(1);
}

.blocProducts {
  margin-top: 20px;
  padding: 0 7%;
}

.item {
  cursor: pointer;
  z-index: 1;
  position: relative;
  transition: color 0.3s ease-in-out;
}

.sliding-bg {
  position: absolute;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  border: 1px solid lightgray;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  transition: transform 0.5s ease, width 0.3s ease;
  z-index: 0;
}

.custom-table {
  min-width: 900px;
	border-collapse: collapse; 
}
.custom-table thead tr, .custom-table thead th {
  border-top: none;
  border-bottom: none !important;
}

.custom-table tbody th, .custom-table tbody td {
  color: #777;
  font-weight: 400;
  padding-bottom: 20px;
  padding-top: 20px;
  font-weight: 300;
}
  
.custom-table tbody th small, .custom-table tbody td small {
  color: #b3b3b3;
  font-weight: 300;
}

.custom-table tbody .persons {
  padding: 0;
  margin: 0;
}
    
.custom-table tbody .persons li {
  padding: 0;
  margin: 0 0 0 -15px;
  list-style: none;
  display: inline-block;
}

.custom-table tbody .persons li a {
  display: inline-block;
  width: 36px;
}

.custom-table tbody .persons li a img {
  border-radius: 50%;
  max-width: 100%;
}

.custom-table tbody tr th, .custom-table tbody tr td {
  position: relative;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
  
.custom-table tbody tr th:before, .custom-table tbody tr th:after, .custom-table tbody tr td:before, .custom-table tbody tr td:after {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
	content: "";
	left: 0;
	right: 0;
	position: absolute;
	height: 1px;
	background: #bfbfbf;
	width: 100%;
	opacity: 0;
	visibility: hidden;
}

.custom-table tbody tr th:before, .custom-table tbody tr td:before {
	top: -1px;
}

.custom-table tbody tr th:after, .custom-table tbody tr td:after {
	bottom: -1px;
}

.custom-table tbody tr:hover th, .custom-table tbody tr:hover td {
  background: rgba(0, 0, 0, 0.03);
}

.custom-table tbody tr:hover th:before, .custom-table tbody tr:hover th:after, .custom-table tbody tr:hover td:before, .custom-table tbody tr:hover td:after { 
	opacity: 1;
  visibility: visible;
}

.custom-table tbody tr.active th, .custom-table tbody tr.active td {
  background: rgba(0, 0, 0, 0.03);
}

.custom-table tbody tr.active th:before, .custom-table tbody tr.active th:after, .custom-table tbody tr.active td:before, .custom-table tbody tr.active td:after {    
	opacity: 1;
  visibility: visible;
}

.selected-header {
  color: rgb(0, 0, 0);
	font-size: 12px;
}

.dimmed-header {
  color: rgb(150, 150, 150);
	font-size: 12px;
}

.highlighted-value {
  font-weight: bold;
  color: black;
}

.dimmed-value {
  color: rgb(168, 168, 168);
}

.name-column {
  width: 300px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.progress {
  height: 0.7rem;
}

.headers {
  font-size: 12px;
}

.items {
    font-size: 11px;
}
  
#chartdiv {
	width: 100%;
  height: 500px;
}
</style>
  