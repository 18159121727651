<template>
  <!-- ############################################################################### -->
  <!-- ############## Pop-up d'informations d'une graphique sélectionné ############## -->
  <!-- ############################################################################### -->
  <div v-if="infos_chart" class="opacity_background"></div>
  <div v-if="infos_chart" class="infos_chart fade-in">
    <div class="titles_infos" v-if="series.graph_title">
      <h2 v-if="year_map != null && series.graph_title != null && (series.type == 'Map')">{{
        series.graph_title + ' ' +
        year_map.substring(0, 4) }}
      </h2>
      <h2 v-else-if="year_map != null && series.graph_title == null && (series.type == 'Map')">
        {{ series.name + ' ' +
          year_map.substring(0, 4) }}
      </h2>
      <h2 v-else-if="series.graph_title">{{ series.graph_title }}</h2>
      <h2 v-else>{{ series.name }}</h2>
      <h3>{{ series.graph_subtitle }}</h3>
    </div>
    <div class="title_infos" v-else>
      <h2>{{ series.name }}</h2>
      <h3>{{ series.unit }}</h3>
    </div>
    <table>
      <thead>
        <tr v-if="series.legend && series.type != 'Map'">
          <th>Legend</th>
          <th>Datalab Code</th>
        </tr>
        <tr v-else>
          <th>Datalab Code</th>
        </tr>
      </thead>
      <tbody v-if="series.legend && series.type != 'Map'">
        <tr v-for="(country, index) in series.legend" :key="index">
          <td>{{ country }}</td>
          <template v-for="(code, subIndex) in series.codes[index]" :key="subIndex">
            <td v-if="subIndex === 0">
              <a :href="'https://app.taceconomics.com/data/' + code" target="_blank"><span>{{ code }}</span></a>
            </td>
          </template>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <template v-for="(code, subIndex) in series.codes[0]" :key="subIndex">
            <td v-if="subIndex === 0">
              <a :href="'https://app.taceconomics.com/data/' + code" target="_blank"><span>{{ code }}</span></a>
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <div v-if="user.subscriptions.length > 0 || user.email.endsWith('@taceconomics.com')" class="buttons_infos">

      <button @click="downloadPDF(series.id)">PDF (graph) <i class="fa-solid fa-file-pdf"></i></button>
      <button @click="downloadPNG(series.id)">Image (graph) <i class="fa-solid fa-file-image"></i></button>

      <!-- Bouton Export XLSX selon le type de tableau de données -->
      <button v-if="datas_radar.series.length != 0" @click="exportXLSX(datas_radar, 'Radar')">Excel (data) <i
          class="fa-solid fa-file-excel"></i></button>
      <button v-else-if="datas_category.series.length != 0" @click="exportXLSX(datas_category, 'Category')">Excel (data)
        <i class="fa-solid fa-file-excel"></i></button>
      <button v-else-if="datas_map.series.length != 0" @click="exportXLSX(datas_map, 'Map')">Excel (data)
        <i class="fa-solid fa-file-excel"></i></button>
      <button v-else-if="datas_table.series.length != 0" @click="exportXLSX(datas_table, 'Table')">Excel (data)
        <i class="fa-solid fa-file-excel"></i></button>
      <button v-else-if="datas_hybridChart.series.length != 0"
        @click="exportXLSX(datas_hybridChart, 'Hybrid Chart')">Excel (data)
        <i class="fa-solid fa-file-excel"></i></button>
      <button v-else @click="exportXLSX(datas)">Excel (data) <i class="fa-solid fa-file-excel"></i></button>

      <!-- Bouton Export CSV selon le type de tableau de données -->
      <button v-if="datas_radar.series.length != 0" @click="exportCSV(datas_radar, 'Radar')">CSV (data) <i
          class="fa-solid fa-file-csv"></i></button>
      <button v-else-if="datas_category.series.length != 0" @click="exportCSV(datas_category, 'Category')">CSV (data)
        <i class="fa-solid fa-file-csv"></i></button>
      <button v-else-if="datas_map.series.length != 0" @click="exportCSV(datas_map, 'Map')">CSV (data)
        <i class="fa-solid fa-file-csv"></i></button>
      <button v-else-if="datas_table.series.length != 0" @click="exportCSV(datas_table, 'Table')">CSV (data)
        <i class="fa-solid fa-file-csv"></i></button>
      <button v-else-if="datas_hybridChart.series.length != 0" @click="exportCSV(datas_hybridChart, 'Hybrid Chart')">CSV
        (data)
        <i class="fa-solid fa-file-csv"></i></button>
      <button v-else @click="exportCSV(datas)">CSV (data) <i class="fa-solid fa-file-csv"></i></button>

    </div>

    <div class="buttons_infos" v-else>
      <div class="buttons_disabled alert alert-warning text-center">You need a <strong>premium</strong> subscription to be
        able to download
        pictures and/or data. You can
        see the
        different subscription
        plans <a href="http://app.taceconomics.com/pricing">here</a>.</div>
    </div>

    <div class="close" @click="close_infos_chart">
      <i class="fa-solid fa-xmark"></i>
    </div>
  </div>

  <!-- ############################################################################### -->
  <!-- ################################## Graphique ################################## -->
  <!-- ############################################################################### -->
  <div :class="series.type === 'Sorted Bar' || series.type === 'BubbleChart' ? 'card_sorted_bar' : ''"
    class="test card p-4" :id="'chart-' + series.id">

    <!-- Titre et sous-titre du graphique -->
    <h2 class="mb-2 text-left title" v-if="year_map != null && series.graph_title != null && (series.type == 'Map')">{{
      series.graph_title + ' ' + year_map.substring(0, 4) }}
    </h2>
    <h2 class="mb-2 text-left title" v-else-if="year_map != null && series.graph_title == null && (series.type == 'Map')">
      {{ series.name + ' ' + year_map.substring(0, 4) }}
    </h2>
    <h2 class="mb-2 text-left title" v-else-if="series.graph_title">{{ series.graph_title }}</h2>
    <h2 class="mb-2 text-left title" v-else>{{ series.name }}</h2>
    <h3 class="mb-3 text-left subtitle" v-if="series.graph_subtitle"><span>{{ series.graph_subtitle }}</span></h3>
    <h3 class="mb-3 text-left subtitle" v-else><span>{{ series.unit }}</span></h3>

    <!-- Logo de chargement du graphique -->
    <div v-if="loading" class="loader"></div>

    <!-- Bloc HTML du Graphique -->
    <div class="chart" ref="chartdiv"></div>

    <!-- Ajout à la main de la légende du graphique s'il y a une zone grise -->
    <div v-if="legend_grey_area" class="custom-legend">
      <div class="legend-icon"></div>
      <span class="legend-label">{{ this.title_area }}</span>
    </div>

    <!-- Menu burger en haut à droite pour les infromations du graphique -->
    <div @click="display_infos_chart" class="menu-button close close2">
      <i class="fa-solid fa-bars"></i>
    </div>

    <div class="source">
      <div class="src-txt">Source : {{ series.source }}</div>
      <img class="logo" src="../assets/img/taclogo.png" alt="Logo" height="35" width="55">
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import * as am4maps from "@amcharts/amcharts4/maps"
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import { mapGetters } from "vuex";

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

am4core.useTheme(am4themes_animated);

// https://stackoverflow.com/questions/57572182/making-am4charts-reactive-in-vuejs

export default {
  props: {
    graph_title: String,
    graph_subtitle: String,
    from: String,
    end: String,
    serie: String,
    legend: { type: String, default: null },
    colors: { type: String, default: null },
    scrollbar: { type: Boolean, default: true },
    collapse: String,
    mode_collapse: String,
    type: String,
    heatmap_conditions: { type: String, default: null },
    stacked: Boolean,
    stacked_total_line: String,
    grey_area: String,
    title_area: String,
    multi_country: String,
    country_stat: String,
    countries: Array,
    source: String,
    right: Boolean,
    codes_right: { type: String, default: null },
    last_date: Boolean,
    dimensions: { type: String, default: null },
    year_map: String,
    decimals: String,
    country_selectedMap: String,
    yInterval: String,
    xInterval: String,
    corners_labels: Array,
  },
  computed: {
    ...mapGetters(["email"]),
  },
  data() {
    return {
      series: {},
      chart: '',
      infos_chart: false,
      chartInstance: null,
      datas: {
        timestamp: [],
        series: [],
      },
      datas_radar: {
        series: [],
        value: [],
      },
      datas_category: {
        category: [],
        series: [],
        value: [],
      },
      datas_map: {
        category: [],
        series: [],
        value: [],
      },
      datas_table: {
        series: [],
        value: [],
      },
      datas_hybridChart: {
        year: [],
        series: [],
        value: []
      },
      legend_grey_area: false,
      legend_map: false,
      dimensions_info: {},
      user: {
        email: "",
        firstname: "",
        lastname: "",
        company_name: "",
        api_key: "",
        subscriptions: []
      },
      loading: true,
      country_map_Selected: null,
      selectedCountryId: null,
    }
  },
  methods: {
    loadChart() {
      console.log('Values have changed');
      this.chart.invalidateRawData();
    },
    getColorHeatMap(value) {
      // Attribution des couleurs selon les conditions de la heatmap
      for (let i = 0; i < this.heatmap_conditions.length; i++) {
        const range = this.heatmap_conditions[i][0];
        const color = this.heatmap_conditions[i][1];

        const [lower, upper] = range.split('-');

        // Vérification les conditions dynamiques
        if (lower.startsWith('<') && value < parseFloat(lower.slice(1))) {
          return am4core.color(color);
        } else if (upper && value > parseFloat(lower) && value < parseFloat(upper)) {
          return am4core.color(color);
        } else if (lower.startsWith('>') && value > parseFloat(lower.slice(1))) {
          return am4core.color(color);
        }
      }

      // Valeur par défaut si aucune condition ne correspond
      return am4core.color("#000000");
    },
    sort_tab_histo(tab) {
      // Tri par ordre décroissant d'un histogramme, empilé ou pas (max : 4 valeurs différentes)
      function compareValues(a, b) {
        let sumA = null;
        let sumB = null;

        if (Object.keys(a).length - 1 == 2) {
          sumA = a.value1 + a.value2;
          sumB = b.value1 + b.value2;
        }
        else if (Object.keys(a).length - 1 == 3) {
          sumA = a.value1 + a.value2 + a.value3;
          sumB = b.value1 + b.value2 + b.value3;
        }
        else {
          sumA = a.value1 + a.value2 + a.value3 + a.value4;
          sumB = b.value1 + b.value2 + b.value3 + b.value4;
        }

        // Tri décroissant
        if (sumA > sumB) {
          return -1;
        } else if (sumA < sumB) {
          return 1;
        } else {
          return 0;
        }
      }

      // Tri du tableau "tab" en utilisant la fonction de comparaison
      tab.sort(compareValues);
      return tab;
    },
    sort_tab_sorted_bar(tab) {
      // Tri par ordre décroissant d'un histogramme en ligne
      function compareValues(a, b) {
        // Tri décroissant
        if (a.value > b.value) {
          return -1;
        } else if (a.value < b.value) {
          return 1;
        } else {
          return 0;
        }
      }

      // Tri du tableau "tab" en utilisant la fonction de comparaison
      tab.sort(compareValues);
      return tab;
    },
    async renderChart() {

      // Affiche la barre de chargement
      this.loading = true;

      // Nombre de chiffres après la virgule du graphique
      let decimals = null;
      switch (this.decimals) {
        case "0":
          decimals = "#."
          break;

        case "1":
          decimals = "#.#"
          break;

        case "2":
          decimals = "#.##"
          break;

        case "3":
          decimals = "#.###"
          break;

        default:
          decimals = "#.##"
          break;
      }

      /* ########################################################################################### */
      /* ####################### CONSTRUCTION DE TOUS LES LIENS ET PARAMETRES ###################### */
      /* ########################################################################################### */
      let codes = [];
      let liens = [];

      codes = this.serie.split(",");

      codes.forEach(function (chaine) {
        let chaineDivisee = chaine.split(":");
        liens.push(chaineDivisee);
      });

      // Ligne total d'un histogramme empilé
      if (this.stacked_total_line) {
        liens.push([this.stacked_total_line]);
      }

      // Date de début du graphique
      liens.push(this.from);

      // Fréquence
      if (this.collapse != null) {
        liens.push(this.collapse);
        liens.push(this.mode_collapse);
      }

      // Plusieurs pays
      if (this.multi_country) {
        if (!this.countries) {
          for (let i = 0; i < liens.length - 3; i++) {
            liens.splice(i, 1, [liens[i][0] + "/" + this.country_stat])
          }
        }
      }

      let liens_tmp = liens.slice()
      var list_dimensions = []

      // Plusieurs dimensions comme "banks" ou autre chose...
      if (this.dimensions != null) {
        // On récupère selon le champ dans dimensions et faisons un appel sur l'api avec ça
        let index_dimension = Object.keys(this.dimensions[0])[0];

        let api_request = "/data/" + index_dimension;

        await api.get(api_request).then(response => {
          this.dimensions_info = response.data.data;
        })

        let dimensions = this.dimensions[0][index_dimension]


        if(this.type == "Hybrid ColumnSeries"){
            list_dimensions = dimensions.map((code) => {
            const item = this.dimensions_info.find((element) => element.id === code);
            return { name: item ? item.short_name : '', code };
          });
        }else{
          list_dimensions = dimensions.map((code) => {
            const item = this.dimensions_info.find((element) => element.id === code);
            return { name: item ? item.name : '', code };
          });
        }

      } else {
        // Par défaut, on met rien pour qu'on puisse parcourir qu'une seule fois
        list_dimensions = [null];
      }

      /* ################################################################################################ */
      /* ############## CONSTRUCTION DE TOUS LES LIENS ET PARAMETRES A DROITE DU GRAPHIQUE ############## */
      /* ################################################################################################ */

      let codes_right = [];
      let liens_right = [];

      if (this.right) {

        codes_right = this.codes_right[0].split(",");

        codes_right.forEach(function (chaine) {
          let chaineDivisee = chaine.split(":");
          liens_right.push(chaineDivisee);
        });

        liens_right.push(this.from);

        if (this.collapse != null) {
          liens_right.push(this.collapse);
          liens_right.push(this.mode_collapse);
        }

        liens_right.push(this.codes_right[1])
        liens_right.push(this.codes_right[2])
      }

      /* ################################################################################################ */
      /* ################################### SPECIFICATION DU GRAPHIQUE ################################# */
      /* ################################################################################################ */

      let chart = null;

      // Construction du chart en fonction du type du graphique
      if (this.type == "RadarSeries") {
        chart = am4core.create(this.$refs.chartdiv, am4charts.RadarChart)
      }
      else if (this.type == "Map") {
        chart = am4core.create(this.$refs.chartdiv, am4maps.MapChart)
      }
      else if (this.type == "Table") {
        chart = this.$refs.chartdiv;
      }
      else {
        chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)
      }

      this.chartInstance = chart;

      if (this.type != "Table") {
        chart.hiddenState.properties.opacity = 0;
      }

      let categoryAxis;
      let valueAxis;
      let dateAxis;

      ////////////////////////////////////////////////////////////////
      // Attribution des 2 axes du graphique selon différents types //
      ////////////////////////////////////////////////////////////////
      if (this.type == "RadarSeries") {
        categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.labels.template.horizontalCenter = "middle";
        categoryAxis.dataFields.category = "name";
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.tooltip.disabled = true;

        let categoryLabel = categoryAxis.renderer.labels.template;
        categoryLabel.fontSize = 17;
        categoryLabel.fontWeight = "bold";
        categoryLabel.contentValign = "center";
      }
      else if (this.dimensions && this.type == "ColumnSeries") {
        categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "name";
        categoryAxis.renderer.labels.template.rotation = -90;
        categoryAxis.renderer.minGridDistance = 0;
        categoryAxis.renderer.fontSize = 12;
        categoryAxis.renderer.labels.template.verticalCenter = "middle";
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.maxWidth = 120;
      }
      else if (this.type == "Sorted Bar") {
        categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "name";
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.fontSize = 12;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.cellStartLocation = 0.2;
        categoryAxis.renderer.cellEndLocation = 0.7;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.tooltip.disabled = true;

        valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.inversed = false;
        valueAxis.renderer.minGridDistance = 50;
        valueAxis.numberFormatter.numberFormat = decimals;
      }
      
      else if(this.dimensions && this.type == "Hybrid ColumnSeries"){

        categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "name";
        categoryAxis.renderer.fontSize = 12;
        categoryAxis.renderer.minGridDistance = 0;
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.labels.template.maxWidth = 120;

        valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.numberFormatter.numberFormat = decimals;
        valueAxis.renderer.grid.template.disabled = true;
      }

      // Par défaut : Axe de dates, mais pour les autres types c'est à la fin qu'on fait ces choses
      else if (this.type != "HeatMap" && this.type != "BubbleChart" && this.type != "Map" && this.type != "Table" && this.type != "HybridChart" && this.type != "RadarSeries" && this.type != "Sorted Bar" && this.type != "Hybrid ColumnSeries"  ) {
        dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.tooltipDateFormat = "yyyy-MM-dd";
        dateAxis.skipEmptyPeriods = false;
        // dateAxis.renderer.grid.template.location = 0;
        dateAxis.tooltipDateFormat = "yyyy-MM-dd";
        dateAxis.renderer.minGridDistance = 70;
        dateAxis.dateFormats.setKey("year", "yyyy");
        dateAxis.periodChangeDateFormats.setKey("year", "yyyy");
        
        // Aligner les lignes de la grille avec les légendes de l'axe des dates
        dateAxis.renderer.labels.template.location = 0.1;
        dateAxis.renderer.grid.template.strokeOpacity = 1;
        dateAxis.renderer.grid.template.stroke = am4core.color("#eaeaea");
      }

      if (this.type != "HeatMap" && this.type != "BubbleChart" && this.type != "Sorted Bar" && this.type != "Map" && this.type != "Table" && this.type != "HybridChart") {
        valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.numberFormatter.numberFormat = decimals;

        valueAxis.renderer.labels.template.adapter.add("text", function (text) {
          // Formatte la valeur avec un espace tous les milliers
          return parseFloat(text).toLocaleString();
        });
      }

      let valueAxis2;
      if (this.right) {
        valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis())
        valueAxis2.numberFormatter.numberFormat;
        valueAxis2.tooltip.disabled = true;
        valueAxis2.renderer.opposite = true;
        valueAxis2.syncWithAxis = valueAxis;
      }

      let seriesArray = [];

      //////////////////////////////////////////////////////////
      // Attribution de la légende selon le type du graphique //
      //////////////////////////////////////////////////////////
      let legend = null;

      if (this.type != "RadarSeries" && this.type != "HeatMap" && this.type != "BubbleChart" && this.type != "Sorted Bar" && this.type != "Map" && this.type != "Table" && this.type != "HybridChart") {
        if (codes.length == 1 && this.title_area) {
          this.legend_grey_area = true;
        } else {
          if (this.legend != null || this.title_area) {
            legend = new am4charts.Legend();
            chart.legend = legend;
            legend.align = "center";
            legend.valign = "middle";
          }
        }
      }

      //////////////////////////////////////////////////////////////////////////////////////
      // Construction de différents tableaux pour stocker des données selon le type aussi //
      //////////////////////////////////////////////////////////////////////////////////////
      let tab_colors = [];

      let total;

      if (this.right) {
        total = liens.length + liens_right.length - 8
      } else {
        total = liens.length - 3
      }

      this.series.datas = {};
      this.series.datas_radar = {};
      this.series.datas_category = {};
      this.series.datas_map = {}
      this.series.datas_table = {}
      this.series.datas_hybridChart = {}

      let data_radar_chart = [];
      let data_histo_empile_chart = [];

      if ((this.dimensions) && (this.type == "ColumnSeries" || this.type == "HybridChart" || this.type == "Hybrid ColumnSeries")) {
        for (let i = 0; i < list_dimensions.length; i++) {
          
          this.datas_category.category.push(list_dimensions[i].code)
          let categoryObj = {
            name: list_dimensions[i].name
          };

          for (let j = 1; j <= liens.length - 3; j++) {
            categoryObj[`value${j}`] = null;
          }

          data_histo_empile_chart.push(categoryObj);
        }
      }

      if (this.type == "Sorted Bar") {
        for (let i = 0; i < list_dimensions.length; i++) {

          this.datas_category.category.push(list_dimensions[i].code)

          let categoryObj = {
            name: list_dimensions[i].name
          };

          categoryObj[`value`] = null;

          data_histo_empile_chart.push(categoryObj);
        }
      }

      let data_heat_map = [];
      let data_heat_map2 = [];

      if (this.type == "HeatMap") {
        list_dimensions.forEach(item => {
          this.datas_category.category.push(item.code)

          // Parcours des légendes
          this.legend.forEach(legendItem => {
            // Création d'un objet pour chaque combinaison bank/légende avec les propriétés "bank", "legend" et "value"
            const bankObj = {
              legend: legendItem,
              name: item.name,
              value: null
            };
            // Ajout de l'objet au tableau "tab"
            data_heat_map.push(bankObj);
          });
        });
      }

      let data_bubbleChart = [];

      if (this.type == "BubbleChart") {
        for (var i = 0; i < list_dimensions.length; i++) {

          this.datas_category.category.push(list_dimensions[i].code)

          var newItem = {};

          for (var j = 0; j < this.legend.length; j++) {
            var legend_item = this.legend[j];
            newItem[legend_item] = null;
          }
          newItem["value"] = null;
          newItem["legend"] = null;

          data_bubbleChart.push(newItem);
        }
      }

      let data_map = [];

      if (this.type == "Map") {
        for (var k = 0; k < this.countries.length; k++) {

          this.datas_map.category.push(this.countries[k].id)
          var ItemMap = {};

          ItemMap["name"] = this.countries[k].short_name;
          ItemMap["iso"] = this.countries[k].id;
          ItemMap["value"] = null;

          data_map.push(ItemMap);
        }

        list_dimensions = data_map
      }

      let data_table = [];

      if (this.type == "Table") {
        for (let i = 0; i < this.legend.length; i++) {

          this.datas_table.series.push(liens[i])

          var ItemTable = {};

          ItemTable["legend"] = this.legend[i];
          ItemTable["value"] = null;

          data_table.push(ItemTable);
        }
      }

      let data_hybridchart = [];

      if (this.type == "HybridChart") {

        for (let i = 0; i < liens.length - 3; i++) {
          this.datas_hybridChart.series.push(liens[i]);
        }

        let taille = Number(this.end.slice(0, 4)) - Number(this.from.slice(0, 4)) + 1;

        let tab_year = [];
        for (let i = 0; i < taille; i++) {
          tab_year.push(Number(this.from.slice(0, 4)) + i)
        }

        let tab_year_datas = [];
        for (let i = 0; i < taille; i++) {
          tab_year_datas.push(String(Number(this.from.slice(0, 4)) + i) + this.from.slice(4, 10))
        }

        this.datas_hybridChart.year = tab_year_datas;

        for (let i = 0; i < taille; i++) {

          var ItemHybridCH = {};

          ItemHybridCH["x"] = null;
          ItemHybridCH["y"] = null;
          ItemHybridCH["value"] = tab_year[i];

          data_hybridchart.push(ItemHybridCH);
        }

        list_dimensions = tab_year;
      }

      /* ################################################################################################ */
      /* ###################################### BOUCLES GENERALES ####################################### */
      /* ################################################################################################ */
      for (let j = 0; j < list_dimensions.length; j++) {

        // console.log("j =", j);
        if (this.dimensions) {
          // Si on veut un graphique Hybrid Columnseries on fait en sorte d'ajouter le nom de la dimension (Exemple l'iso d'un pays) après la date donnée. 
          if(this.type == "Hybrid ColumnSeries"){
            for (let i = 0; i < liens_tmp.length - 3; i++) {
              const indicatorIndex = liens_tmp[i][0].indexOf("?") !== -1 ? liens_tmp[i][0].indexOf("?") : liens_tmp[i][0].length;
              liens.splice(i, 1, [liens_tmp[i][0].substring(0, indicatorIndex) + "/" + list_dimensions[j].code + liens_tmp[i][0].substring(indicatorIndex)]);
            }
          }else{
            for (let i = 0; i < liens_tmp.length - 3; i++) {
            liens.splice(i, 1, [liens_tmp[i][0] + "/" + list_dimensions[j].code])
            }
          }
        }

        if (this.countries) {
          for (let i = 0; i < liens_tmp.length - 3; i++) {
            liens.splice(i, 1, [liens_tmp[i][0] + "/" + data_map[j].iso])
          }
        }

        let values = [];

        for (let i = 0; i < total; i++) {

          /* ################################################################################################ */
          /* #################################### CONSTRUCTION DE L'URL ##################################### */
          /* ################################################################################################ */

          let url = "/data/";

          // S'il y a des codes à l'axe de droite 
          if (this.right) {
            if (i < liens.length - 3) {
              url += liens[i][0];
              if (this.year_map != null && (this.type == "Map" || this.type == "Table")) {
                if (liens[liens.length - 3]) url += "?start_date=" + this.year_map + "&end_date=" + this.year_map + "&collapse=" + liens[liens.length - 2] + "&mode_collapse=" + liens[liens.length - 1];
              } else {
                if (liens[liens.length - 3]) url += "?start_date=" + liens[liens.length - 3] + "&collapse=" + liens[liens.length - 2] + "&mode_collapse=" + liens[liens.length - 1];
              }

              if (liens[i][1]) url += "&transform=" + liens[i][1];
              if (this.last_date) url += "&last_value=1";
            } else {
              url += liens_right[i - (liens.length - 3)][0];
              if (this.year_map != null && (this.type == "Map" || this.type == "Table")) {
                if (liens_right[liens_right.length - 3]) url += "?start_date=" + this.year_map + "&end_date=" + this.year_map + "&collapse=" + liens[liens.length - 2] + "&mode_collapse=" + liens[liens.length - 1];
              } else {
                if (liens_right[liens_right.length - 3]) url += "?start_date=" + liens[liens.length - 3] + "&collapse=" + liens[liens.length - 2] + "&mode_collapse=" + liens[liens.length - 1];
              }
              if (liens_right[i - (liens.length - 3)][1]) url += "&transform=" + liens_right[i - (liens.length - 3)][1];
              if (this.last_date) url += "&last_value=1";
              // valueAxis2.title.text = this.legend[i];
              // valueAxis2.title.rotation = -90;
              // valueAxis2.stroke = am4core.color(this.colors[i]);
            }
          }
          else {
            url += liens[i][0];
            let start = false;
            if (this.year_map != null && (this.type == "Map" || this.type == "Table")) {
              if (liens[liens.length - 3]) url += "?start_date=" + this.year_map + "&end_date=" + this.year_map + "&collapse=" + liens[liens.length - 2] + "&mode_collapse=" + liens[liens.length - 1];
              start = true;
            }
            else if (this.end && (this.type != "Map" || this.type != "Table")) {
              // On change la date à chaque requête avec un Hybrid Chart
              if (this.type == "HybridChart") {
                if (liens[liens.length - 3]) url += "?start_date=" + list_dimensions[j] + "-01-01" + "&end_date=" + list_dimensions[j] + "-01-01" + "&collapse=" + liens[liens.length - 2] + "&mode_collapse=" + liens[liens.length - 1];
              }
              else {
                if (liens[liens.length - 3]) url += "?start_date=" + liens[liens.length - 3] + "&end_date=" + this.end + "&collapse=" + liens[liens.length - 2] + "&mode_collapse=" + liens[liens.length - 1];
              }
              start = true;
            }
            else if(this.type == "Hybrid ColumnSeries"){
              if (liens[liens.length - 3]) url += "&collapse=" + liens[liens.length - 2] + "&mode_collapse=" + liens[liens.length - 1];
              start = true;
            }
            else {
              if (liens[liens.length - 3]) url += "?start_date=" + liens[liens.length - 3] + "&collapse=" + liens[liens.length - 2] + "&mode_collapse=" + liens[liens.length - 1];
              start = true;
            }
            if (liens[i][1]) url += "&transform=" + liens[i][1];
            if (this.last_date && start) url += "?last_value=1";
            if (this.last_date && start) url += "&last_value=1";
          }

          // console.log(url);

          /* ################################################################################################ */
          /* ################################## REQUETE API SUR URL ######################################### */
          /* ################################################################################################ */

          await api.get(url).then(response => {

            this.series = response.data;

            // Informations pour les éléments HTML dans template
            this.series.graph_title = this.graph_title;
            this.series.graph_subtitle = this.graph_subtitle;
            this.series.source = this.source;
            this.series.legend = this.legend;
            this.series.type = this.type;

            /* S'il y a des données à afficher sur la droite, 
            on cocatène avec les autres pour les codes à afficher dans les informations d'un graphique */
            if (liens_right.length > 0) {
              this.series.codes = liens.slice(0, -3).concat(liens_right.slice(0, -5));
            } else {
              this.series.codes = liens.slice(0, -3)
              // S'il y a plusieurs banques, on mettra que les 2 premiers élements de l'appel sur l'api
              if (this.dimensions) {
                let tab = this.series.codes;
                var new_codes = tab.map(function (tab) {
                  var lastIndex = tab[0].lastIndexOf('/');
                  var updatedSubArray = tab[0].slice(0, lastIndex);
                  return [updatedSubArray];
                });
                this.series.codes = new_codes;
              }
            }

            let series;

            // Si un type n'est pas choisi, c'est automatiquement une ligne/courbe
            if (this.type) {
              if (this.type != "HeatMap" && this.type != "BubbleChart" && this.type != "Map" && this.type != "Table" && this.type != "HybridChart") {
                if (this.type == "Sorted Bar" || this.type == "Hybrid ColumnSeries") {
                  series = new am4charts.ColumnSeries();
                } else {
                  series = new am4charts[this.type]();
                }
              }
            } else {
              series = new am4charts.LineSeries();
            }

            // S'il y a un histogramme empilé et qu'on veut une courbe total
            if (this.stacked_total_line && i == total - 1) {
              series = new am4charts.LineSeries();
            }

            if (i >= liens.length - 3) {
              series = new am4charts[liens_right[4]]();
            }

            // Choix de la propriété des axes selon le type de graphique
            if (this.type != "HeatMap" && this.type != "BubbleChart" && this.type != "Map" && this.type != "Table" && this.type != "HybridChart") {
              if (this.dimensions && this.type == "ColumnSeries") {
                series.dataFields.categoryX = "name";
                series.dataFields.valueY = "value" + (i + 1);
              }
              else if(this.dimensions && this.type == "Hybrid ColumnSeries"){
                series.dataFields.categoryY = "name";
                series.dataFields.valueX = "value" + (i + 1);
              }
              else if (this.type == "Sorted Bar") {
                series.dataFields.categoryY = "name";
                series.dataFields.valueX = "value";
              }
              else {
                if (this.type == "RadarSeries") {
                  series.dataFields.categoryX = "name";
                } else {
                  series.dataFields.dateX = "timestamp";
                }
                series.dataFields.valueY = "value";
                series.dataFields.valueY2 = "value";
              }
            }

            // Choix de l'axe à se baser quand on insère les données
            if (this.type != "HeatMap" && this.type != "BubbleChart" && this.type != "Sorted Bar" && this.type != "Map" && this.type != "Table" && this.type != "HybridChart" && this.type != "Hybrid ColumnSeries") {
              if (this.type != "RadarSeries") {
                if (i < liens.length - 3) {
                  series.yAxis = valueAxis;
                } else {
                  series.yAxis = valueAxis2;
                }
              }
            }
            // Si c'est un histogramme avec champ stacked => empilé
            if (this.stacked) {
              series.stacked = true;
            }

            /* ################################################################################################ */
            /* ######################################### COULEURS ############################################# */
            /* ################################################################################################ */
            let color = this.getRandomColor();

            if (this.dimensions) {
              if (j == 0) {                // Cela prendra toujours une couleur différente pour pas qu'il y ait de doublons de couleurs dans un graphique
                if (tab_colors.includes(color)) {
                  while (tab_colors.includes(color)) {
                    color = this.getRandomColor();
                  }
                }
                tab_colors.push(color);
              } else {
                color = tab_colors[i];
              }
            } else {
              // Cela prendra toujours une couleur différente pour pas qu'il y ait de doublons de couleurs dans un graphique
              if (!this.countries && this.type != "HybridChart") {
                if (tab_colors.includes(color)) {
                  while (tab_colors.includes(color)) {
                    color = this.getRandomColor();
                  }
                }
                tab_colors.push(color);
              }
            }

            if (this.type != "HeatMap" && this.type != "BubbleChart" && this.type != "Sorted Bar" && this.type != "Map" && this.type != "Table" && this.type != "HybridChart" ) {
              if (this.legend) {
                  series.tooltip.getFillFromObject = false;
                  series.legendSettings.labelText = this.legend[i];
              }
            }

            // S'il y a des couleurs prédéfinis (le cas où il y a des graphiques avec des mêmes légendes)
            if (this.colors && this.type != "BubbleChart") {

              if (this.stacked_total_line && i == total - 1) {
                series.stroke = am4core.color(this.colors[i]);
                series.tooltip.background.fill = am4core.color(this.colors[i]);
              }
              else {
                if (this.type == "ColumnSeries" || this.type == "Sorted Bar" || this.type == "Hybrid ColumnSeries") {
                  series.columns.template.fill = am4core.color(this.colors[i]);
                  series.stroke = am4core.color(this.colors[i]);
                }
                else {
                  if ((i >= liens.length - 3) && (liens_right[4] == "ColumnSeries")) {
                    series.columns.template.fill = am4core.color(this.colors[i]);
                    series.stroke = am4core.color(this.colors[i]);
                    series.fillOpacity = 0.4;

                    let columnTemplate = series.columns.template;
                    columnTemplate.strokeOpacity = 0.4; // Opacité de la bordure

                    // Définition des propriétés de la colonne
                    columnTemplate.width = am4core.percent(80);
                    columnTemplate.height = am4core.percent(80);
                    columnTemplate.column.cornerRadiusTopLeft = 5;
                    columnTemplate.column.cornerRadiusTopRight = 5;
                    columnTemplate.column.fillOpacity = 0.4;
                  } else {
                    series.stroke = am4core.color(this.colors[i]);
                  }
                }
                series.tooltip.background.fill = am4core.color(this.colors[i]);
              }
            } else {
              if (this.type != "HeatMap" && this.type != "BubbleChart" && this.type != "Map" && this.type != "Table" && this.type != "HybridChart") {
                if (this.type == "ColumnSeries" || this.type == "Sorted Bar" || this.type == "Hybrid ColumnSeries") {
                  if (this.dimensions) {
                    series.columns.template.fill = color;
                    series.stroke = color;
                    series.tooltip.background.fill = color;
                  }
                }
                else {
                  if ((i >= liens.length - 3) && (liens_right[4] == "ColumnSeries")) {
                    series.columns.template.fill = color;
                    series.stroke = color;
                  } else {
                    series.stroke = color;
                  }
                  series.tooltip.background.fill = color;
                }
                if (this.type == "RadarSeries") {
                  series.fill = color;
                  series.fillOpacity = 0.2;
                }
              }
            }

            /* ################################################################################################ */
            /* ################################# INSERTION DATA GRAPHIQUE ##################################### */
            /* ################################################################################################ */

            let data_radar;

            if (this.type == "RadarSeries") {
              data_radar = response.data.data.map((item) => ({
                name: this.legend[i],
                value: item.value
              }))[0];

              data_radar_chart.push(data_radar);
            }
            else if (this.type == "HeatMap") {
              if (response.data.data != null) {
                data_heat_map2.push(response.data.data[0].value)
              } else {
                data_heat_map2.push(0)
              }
            }
            else if (this.type == "BubbleChart") {
              if (response.data.data != null) {
                data_bubbleChart[j][this.legend[i]] = response.data.data[0].value
                data_bubbleChart[j].value = Math.floor(Math.random() * (15 - 10 + 1)) + 10;
                data_bubbleChart[j].legend = list_dimensions[j].name;
              }
            }
            else if ((this.dimensions && this.type == "ColumnSeries") || (this.type == "Sorted Bar") || (this.type == "Hybrid ColumnSeries")) {
              if (response.data.data != null) {

                values.push(response.data.data[0].value);
              } else {
                values.push(0);
              }
            }
            else if (this.type == "Map") {
              if (response.data.data != null) {
                data_map[j].value = response.data.data[0].value;
              }
            }
            else if (this.type == "Table") {
              if (response.data.data != null) {
                data_table[i].value = response.data.data[0].value
              }
            }
            else if (this.type == "HybridChart") {
              if (response.data.data != null) {
                if (i == 0) {
                  data_hybridchart[j].x = response.data.data[0].value;
                } else {
                  data_hybridchart[j].y = response.data.data[0].value;
                }
              }
            }
            else {
              series.data = response.data.data;
            }

            //////////////////////////////////////////////////////////////////////
            // Insertion des données pour les données à exporter en CSV ou XLSX //
            //////////////////////////////////////////////////////////////////////
            if (this.type == "RadarSeries") {

              this.datas_radar.series.push(liens[i][0])
              this.datas_radar.value.push(data_radar.value);

              chart.cursor = new am4charts.RadarCursor();
              series.name = this.legend[i];
              series.tooltipText = "{name} : {valueY}"

            }
            else if ((this.dimensions && this.type == "ColumnSeries"))  {
              if (i >= 0 && i < codes.length && j == 0) {
                this.datas_category.series.push(codes[i])
              }
              this.datas_category.value.push(values[i]);
            }
            else if((this.dimensions && this.type == "Hybrid ColumnSeries")){
              if (i >= 0 && i < codes.length && j == 0) {
                this.datas_category.series.push(codes[i])
              }
              this.datas_category.value.push(values[i]);
            }
            else if (this.type == "HeatMap") {
              if (i >= 0 && i < codes.length && j == 0) {
                this.datas_category.series.push(codes[i])
              }
              this.datas_category.value.push(data_heat_map2[i]);
            }
            else if (this.type == "Sorted Bar" ) {
              if (i >= 0 && i < codes.length && j == 0) {
                this.datas_category.series.push(codes[i])
              }
              this.datas_category.value.push(values[i]);
            }
            else if (this.type == "BubbleChart") {
              if (i >= 0 && i < codes.length && j == 0) {
                this.datas_category.series.push(codes[i])
              }
              this.datas_category.value.push(data_bubbleChart[i].value);
            }
            else if (this.type == "Map") {
              if (i >= 0 && i < codes.length && j == 0) {
                this.datas_map.series.push(codes[i])
              }
              this.datas_map.value.push(data_map[j].value);
            }
            else if (this.type == "Table") {
              this.datas_table.value.push(data_table[i].value);
            }
            else if (this.type == "HybridChart") {
              if (i == 0) {
                this.datas_hybridChart.value.push(data_hybridchart[j].x)
              } else {
                this.datas_hybridChart.value.push(data_hybridchart[j].y)
              }
            }
            if (this.type != "Map" && this.type != "BubbleChart" && this.type != "HeatMap" && this.type != "Table" && this.type != "RadarSeries" && this.type != "HybridChart") {
              if (this.type != "Sorted Bar") { 
                if (this.dimensions == null) {
                  this.datas.timestamp = series.data.map(item => item.timestamp);

                  if (this.legend) {
                    if (i >= liens.length - 3) {
                      this.datas.series.push({
                        legend: liens_right[i - (liens.length - 3)][0],
                        data: series.data.map(item => item.value),
                      });
                    } else {
                      // console.log(liens);
                      this.datas.series.push({
                        legend: liens[i][0],
                        data: series.data.map(item => item.value),
                      });
                    }
                  }
                  else {
                    this.datas.series.push({
                      legend: this.graph_title,
                      data: series.data.map(item => item.value),
                    });
                  }
                }
              }

              chart.cursor = new am4charts.XYCursor();
              chart.cursor.lineX.stroke = am4core.color("#8F3985");
              chart.cursor.lineX.strokeWidth = 4;
              chart.cursor.lineX.strokeOpacity = 0.2;
              chart.cursor.lineX.strokeDasharray = "";

              chart.cursor.lineY.stroke = am4core.color("#8F3985");
              chart.cursor.lineY.strokeWidth = 4;
              chart.cursor.lineY.strokeOpacity = 0.2;
              chart.cursor.lineY.strokeDasharray = "";

              // Apparition de la légende ou non avec étiquette personnalisable au survol du graphique
              if (this.legend != null) {
                series.name = this.legend[i];
                series.tooltipText = this.legend[i] + " : {valueY.value}"
                if (this.type == "Hybrid ColumnSeries") {
                  series.tooltipText = "{name} : {valueX}"
                }
              } else {
                if (this.type == "Sorted Bar") {
                  series.tooltipText = "{name} : {valueX}"
                } else {
                  series.tooltipText = "{valueY.value}"
                }
              }
            }

            // Apparition d'une scrollbar en dessous du graphique pour plus de fonctionnalités
            if (this.scrollbar) {
              let scrollbarX = new am4charts.XYChartScrollbar();
              scrollbarX.series.push(series);
              chart.scrollbarX = scrollbarX;
              chart.scrollbarX.parent = chart.bottomAxesContainer;
            }

            if (this.type != "HeatMap" && this.type != "BubbleChart" && this.type != "Map" && this.type != "Table" && this.type != "HybridChart") {
              series.strokeWidth = 3;
              series.showOnInit = false;
            }

            /* ########################################################################################### */
            /* ######################################## ZONE GRISE ####################################### */
            /* ########################################################################################### */
            if (i == liens.length - 4) {

              if (this.grey_area) {

                var range = dateAxis.axisRanges.create();
                range.date = new Date(this.grey_area.split("-")[0], this.grey_area.split("-")[1], this.grey_area.split("-")[2]);
                range.endDate = new Date("3000", "01", "01");
                range.axisFill.fill = am4core.color("grey");
                range.axisFill.fillOpacity = 0.3;

                // Ajout de la zone grise dans la légende
                if (codes.length > 1) {
                  if (this.title_area) {
                    let greyLegend = legend.createChild(am4core.Container);
                    greyLegend.layout = "horizontal";
                    greyLegend.horizontalCenter = true;
                    greyLegend.paddingTop = 10;

                    let greySquare = greyLegend.createChild(am4core.Rectangle);
                    greySquare.width = 20;
                    greySquare.height = 20;
                    greySquare.fill = am4core.color("grey");
                    greySquare.opacity = 0.5;
                    greySquare.marginLeft = 10;

                    let greyLabel = greyLegend.createChild(am4core.Label);
                    greyLabel.text = this.title_area;
                    greyLabel.marginLeft = 5;
                    greyLabel.fontSize = 15;
                  }
                }
              }
            }

            if (this.type == "ColumnSeries" ) {
              if (series.columns != undefined) {
                series.columns.template.width = am4core.percent(40);
              }
            }

            if (this.type == "Sorted Bar") {
              series.columns.template.width = am4core.percent(20);
            }

            // Ajout dans le tableau pour le mettre dans le graphique
            seriesArray.push(series);
          });
        }

        // Ajout si c'est un histogramme empilé avec un axe Catégorie et non de Date
        if (this.dimensions && this.type == "ColumnSeries" || this.type == "Hybrid ColumnSeries") {
          for (let index = 0; index < values.length; index++) {
            let valueIndex = index + 1;
            let valueKey = `value${valueIndex}`;
            data_histo_empile_chart[j][valueKey] = values[index];
          }
        }

        if (this.type == "Sorted Bar") {
          for (let index = 0; index < values.length; index++) {
            data_histo_empile_chart[j].value = values[index];
          }
        }
      }

      /* ################################################################################################ */
      /* ####################  TOUS LES CAS A PART DES AUTRES TYPES DE GRAPHIQUES ####################### */
      /* ################################################################################################ */

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      if (this.type == "Map") {

        // Data par défaut des pays //
        chart.geodata = am4geodata_worldLow;

        chart.projection = new am4maps.projections.Miller();

        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

        polygonSeries.useGeodata = true;

        var polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name} : {value.formatNumber('" + decimals + "')}";

        // Survol d'un pays //
        polygonSeries.mapPolygons.template.adapter.add("tooltipText", function (text, target) {
          var countryData = target.dataItem.dataContext;
          if (countryData.value === null || !countryData.value) {
            return countryData.name + " : N/A";
          } else {
            return countryData.name + " : " + countryData.value.toFixed(2);
          }
        });

        polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        polygonTemplate.fill = am4core.color("#e8e8e8");

        // Create hover state and set alternative fill color
        var hs = polygonTemplate.states.create("hover");
        hs.properties.fill = am4core.color("#021559");

        // Click d'un pays //
        polygonTemplate.events.on("hit", (ev) => {
          var polygon = ev.target;
          var country = polygon.dataItem.dataContext;

          if (country.value) {
            this.selectedCountryId = country.id;
            this.country_selected = this.convertIso(null, country.id);
            this.updateCountrySelected(this.country_selected);

            // Stocker la couleur d'origine du pays
            if (!country.originalColor) {
              country.originalColor = polygon.fill;
            }

            polygon.fill = "black";
          }
        });

        // Remove Antarctica
        polygonSeries.exclude = ["AQ"];

        const countries = [];

        // Créer une légende personnalisée
        let heatLegend = chart.createChild(am4charts.Legend);
        heatLegend.align = "right";
        heatLegend.valign = "middle";
        heatLegend.width = am4core.percent(20); // Modifier l'espace de la légende entre lui-même et la droite
        heatLegend.orientation = "vertical";
        heatLegend.itemContainers.template.paddingTop = 2;
        heatLegend.itemContainers.template.paddingBottom = 2;

        // Créer les éléments de la légende
        var legendItems = [];

        for (let i = 0; i < this.legend.length; i++) {
          let name = this.legend[i].range;
          let fill = this.legend[i].color;

          legendItems.push({ name, fill });
        }

        legendItems.forEach(item => {
          let legendItem = heatLegend.createChild(am4core.Container);
          legendItem.layout = "horizontal";
          legendItem.width = am4core.percent(70);
          legendItem.padding(5, 0, 5, 0);

          // Carré de couleur
          let marker = legendItem.createChild(am4core.Rectangle);
          marker.width = 20;
          marker.height = 20;
          marker.fill = item.fill;
          marker.stroke = am4core.color("#000000");
          marker.strokeWidth = 1;

          // Label
          let label = legendItem.createChild(am4core.Label);
          label.text = item.name;
          label.align = "left";
          label.valign = "middle";
          label.marginLeft = 10;
          label.fontSize = 17;
          label.verticalCenter = "middle";
          label.horizontalCenter = "middle";
        });

        for (let k = 0; k < data_map.length; k++) {
          const countryData = data_map[k];
          const iso2 = this.convertIso(countryData.iso, null);
          let fillColor;

          if (countryData.value === null) {
            fillColor = "#e8e8e8";
          } else {
            for (let i = 0; i < this.legend.length; i++) {
              const legendItem = this.legend[i];
              const range = legendItem.range;
              const color = legendItem.color;

              if (range.includes("-")) {
                const [min, max] = range.split("-").map(value => parseInt(value));

                if (countryData.value >= min && countryData.value < max) {
                  fillColor = color;
                  break;
                }
              } else if (range.includes("<")) {
                const threshold = parseInt(range.slice(1));

                if (countryData.value < threshold) {
                  fillColor = color;
                  break;
                }
              } else if (range.includes(">")) {
                const threshold = parseInt(range.slice(1));

                if (countryData.value > threshold) {
                  fillColor = color;
                  break;
                }
              }
            }
          }

          if (iso2) {

            const polygon = polygonSeries.mapPolygons.create();
            polygon.id = iso2;
            polygon.fill = fillColor;
            polygon.originalColor = fillColor; // Stocker la couleur d'origine du pays

            const country = {
              id: iso2,
              name: countryData.name,
              value: countryData.value,
              fill: fillColor,
              originalColor: fillColor // Stocker la couleur d'origine du pays
            };

            polygon.clickable = true;

            countries.push(country);
          }
        }

        polygonSeries.data = countries;

        polygonTemplate.propertyFields.fill = "fill";
      }

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      if (this.type == "HeatMap") {

        var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

        xAxis.dataFields.category = "name";
        yAxis.dataFields.category = "legend";

        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.minGridDistance = 10;
        xAxis.renderer.labels.template.rotation = -90;
        xAxis.renderer.fontSize = 13;
        xAxis.renderer.labels.template.horizontalCenter = "left";
        xAxis.renderer.labels.template.verticalCenter = "middle"
        xAxis.renderer.opposite = true;

        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.inversed = true;
        yAxis.renderer.minGridDistance = 30;

        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "name";
        series.dataFields.categoryY = "legend";
        series.dataFields.value = "value";
        chart.maskBullets = false;
        series.sequencedInterpolation = true;
        series.defaultState.transitionDuration = 3000;
        series.columns.template.width = am4core.percent(100);
        series.columns.template.height = am4core.percent(100);

        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.stroke = am4core.color("#ffffff");
        columnTemplate.tooltipText = "{name}, {legend}: {value.workingValue.formatNumber('" + decimals + "')}";

        columnTemplate.adapter.add("fill", (fill, target) => {
          if (target.dataItem) {
            const value = target.dataItem.value;
            const color = this.getColorHeatMap(value);
            return color;
          }
          return fill;
        });

        // Appliquer la couleur du carré survolé au tooltip
        chart.tooltip.getFillFromObject = false;
        chart.tooltip.adapter.add("getFill", (fill, target) => {
          if (target.dataItem && target.dataItem.column) {
            return target.dataItem.column.fill;
          }
          return fill;
        });

        legend = new am4charts.Legend();
        legend.parent = chart.chartContainer;
        const legendData = this.heatmap_conditions.map(item => {
          const name = item[0];
          const fill = am4core.color(item[1]);

          return { name, fill };
        });

        // Utilisation dans votre code existant
        legend.data = legendData;

        data_heat_map.forEach((item, index) => {
          // Vérification pour éviter un dépassement de limite dans le tableau "data"
          if (index < data_heat_map2.length) {
            // Remplacement de la valeur de "value" par la donnée correspondante
            item.value = data_heat_map2[index];
          }
        });

        data_heat_map.forEach((item) => {
          item.name = item.name || "N/A";
        });

        chart.data = data_heat_map;
      }

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      if (this.type == "BubbleChart") {

        chart.data = data_bubbleChart;

        // Create axes
        xAxis = chart.xAxes.push(new am4charts.ValueAxis());

        xAxis.renderer.minGridDistance = 40;
        xAxis.title.text = this.legend[0];
        xAxis.title.fontSize = 17;
        xAxis.title.fontWeight = "bold";
        xAxis.title.align = "center";

        yAxis = chart.yAxes.push(new am4charts.ValueAxis());

        yAxis.renderer.minGridDistance = 40;
        yAxis.title.text = this.legend[1];
        yAxis.title.fontSize = 17;
        yAxis.title.rotation = -90;
        yAxis.title.fontWeight = "bold";
        yAxis.title.align = "center";

        // Create series #1
        var main_serie = chart.series.push(new am4charts.LineSeries());
        main_serie.dataFields.valueY = this.legend[1];
        main_serie.dataFields.valueX = this.legend[0];
        main_serie.dataFields.value = "value";
        main_serie.strokeOpacity = 0;

        var bullet = main_serie.bullets.push(new am4charts.CircleBullet());
        if (this.colors) {
          bullet.fill = this.colors[0];
        } else {
          bullet.fill = am4core.color("#E89DF9");
        }
        bullet.stroke = am4core.color("grey");
        bullet.fillOpacity = 0.8;

        bullet.nonScalingStroke = true;
        bullet.tooltipText = this.legend[0] + " : {valueX.workingValue.formatNumber('" + decimals + "')} | " + this.legend[1] + " : {valueY.workingValue.formatNumber('" + decimals + "')}";
        main_serie.heatRules.push({
          target: bullet.circle,
          min: 10,
          max: 10,
          property: "radius"
        });

        var labelBullet = main_serie.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{legend}";
        labelBullet.label.fontSize = 13;
        labelBullet.label.dy = -20;

        var hoverState = bullet.states.create("hover");
        hoverState.properties.scale = 1.4;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.stroke = am4core.color("#8F3985");
        chart.cursor.lineX.strokeWidth = 4;
        chart.cursor.lineX.strokeOpacity = 0.2;
        chart.cursor.lineX.strokeDasharray = "";

        chart.cursor.lineY.stroke = am4core.color("#8F3985");
        chart.cursor.lineY.strokeWidth = 4;
        chart.cursor.lineY.strokeOpacity = 0.2;
        chart.cursor.lineY.strokeDasharray = "";
      }

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      if (this.type == "Table") {

        let table = document.createElement("table")
        let thead = document.createElement("thead");
        let tbody = document.createElement("tbody");

        // Création de l'entête du tableau
        let headerRow = document.createElement("tr");
        let headerLegendCell = document.createElement("th");
        headerLegendCell.textContent = "Legend";
        headerRow.appendChild(headerLegendCell);

        let headerValueCell = document.createElement("th");
        headerValueCell.textContent = "Value";
        headerRow.appendChild(headerValueCell);

        headerLegendCell.style.padding = "1em";
        headerValueCell.style.padding = "1em";
        headerLegendCell.style.border = "solid 2px black";
        headerValueCell.style.border = "solid 2px black";
        headerLegendCell.style.backgroundColor = "rgb(228, 228, 228)";
        headerValueCell.style.backgroundColor = "rgb(228, 228, 228)";

        headerValueCell.style.textAlign = "center";

        headerLegendCell.style.transition = "background-color 0.3s";
        headerValueCell.style.transition = "background-color 0.3s";

        headerLegendCell.addEventListener("mouseover", function () {
          headerLegendCell.style.backgroundColor = "lightgray";
        });
        headerLegendCell.addEventListener("mouseout", function () {
          headerLegendCell.style.backgroundColor = "rgb(228, 228, 228)";
        });

        headerValueCell.addEventListener("mouseover", function () {
          headerValueCell.style.backgroundColor = "lightgray";
        });
        headerValueCell.addEventListener("mouseout", function () {
          headerValueCell.style.backgroundColor = "rgb(228, 228, 228)";
        });

        thead.appendChild(headerRow);
        table.appendChild(thead);

        // Création des lignes de données
        for (let i = 0; i < data_table.length; i++) {
          let dataRow = document.createElement("tr");

          let legendCell = document.createElement("td");
          legendCell.textContent = data_table[i].legend;
          dataRow.appendChild(legendCell);

          let valueCell = document.createElement("td");
          valueCell.textContent = data_table[i].value !== null ? data_table[i].value.toFixed(Number(this.decimals)) : "N/A";
          dataRow.appendChild(valueCell);

          legendCell.style.padding = "1em";
          valueCell.style.padding = "1em";
          valueCell.style.textAlign = "center";
          legendCell.style.border = "solid 1px black";
          valueCell.style.border = "solid 1px black";
          legendCell.style.fontWeight = "bold";

          legendCell.style.transition = "background-color 0.3s";
          valueCell.style.transition = "background-color 0.3s";

          legendCell.addEventListener("mouseover", function () {
            legendCell.style.backgroundColor = "lightgray";
          });
          legendCell.addEventListener("mouseout", function () {
            legendCell.style.backgroundColor = "";
          });

          valueCell.addEventListener("mouseover", function () {
            valueCell.style.backgroundColor = "lightgray";
          });
          valueCell.addEventListener("mouseout", function () {
            valueCell.style.backgroundColor = "";
          });

          tbody.appendChild(dataRow);
        }

        table.style.width = "90%";
        table.appendChild(tbody);
        // Ajouter le tableau à votre élément chart (supposant que vous avez déjà défini chart)
        chart.appendChild(table);
      }

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      if (this.type == "HybridChart") {

        chart.data = data_hybridchart

        let xMin = this.xInterval.split(",")[0];
        let xMax = this.xInterval.split(",")[1];
        let yMin = this.yInterval.split(",")[0];
        let yMax = this.yInterval.split(",")[1];

        // Create axes
        var xAxis2 = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis2.renderer.minGridDistance = 50;
        xAxis2.title.text = this.legend[0];
        xAxis2.title.fontSize = 17;
        xAxis2.title.fontWeight = "bold";
        xAxis2.title.align = "center";
        xAxis2.min = Number(xMin);
        xAxis2.max = Number(xMax);

        var yAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis2.renderer.labels.template.align = "left";
        yAxis2.renderer.minGridDistance = 40;
        yAxis2.title.text = this.legend[1];
        yAxis2.title.fontWeight = 600;
        yAxis2.min = Number(yMin);
        yAxis2.max = Number(yMax);

        var xAxisLine = xAxis2.axisRanges.create();
        xAxisLine.value = 0;
        xAxisLine.grid.strokeWidth = 4;

        // Créer une ligne pour représenter l'axe des Y à (0, 0)
        var yAxisLine = yAxis2.axisRanges.create();
        yAxisLine.value = 0;
        yAxisLine.grid.strokeWidth = 4;

        // Create serie
        var seriesHybrid = chart.series.push(new am4charts.LineSeries());
        seriesHybrid.dataFields.valueX = "x";
        seriesHybrid.dataFields.valueY = "y";
        seriesHybrid.dataFields.value = "value";
        seriesHybrid.strokeWidth = 2;
        seriesHybrid.fill = am4core.color("#021559");
        seriesHybrid.stroke = am4core.color("#021559");

        var bullet2 = seriesHybrid.bullets.push(new am4charts.CircleBullet());
        seriesHybrid.heatRules.push({
          target: bullet2.circle,
          min: 7,
          max: 7,
          property: "radius"
        });

        bullet2.fill = am4core.color("#021559");
        bullet2.stroke = am4core.color("#021559");

        // Ajouter un adaptateur pour définir les couleurs des bulles
        bullet2.adapter.add("fill", function (fill, target) {
          if (target.dataItem.dataContext.value === 2010 || target.dataItem.dataContext.value === 2023) {
            return am4core.color("#C00000"); // Rouge pour la première et la dernière bulle
          }
          return fill; // Couleur par défaut pour les autres bulles
        });

        bullet2.adapter.add("stroke", function (fill, target) {
          if (target.dataItem.dataContext.value === 2010 || target.dataItem.dataContext.value === 2023) {
            return am4core.color("#C00000"); // Rouge pour la première et la dernière bulle
          }
          return fill; // Couleur par défaut pour les autres bulles
        });

        bullet2.tooltip = new am4core.Tooltip();
        bullet2.tooltip.background.fill = am4core.color("#000000");
        bullet2.tooltip.fontSize = "14px";

        bullet2.events.on("over", (event) => {
          let bullet = event.target;
          let dataItem = bullet.dataItem;

          // Afficher le tooltip
          chart.tooltip.show();

          bullet.tooltipHTML = `<span style="font-weight: bold; text-align:center; z-index:9999;">{value}</span><br>` + this.legend[0] + `: ${(dataItem.dataContext.x).toFixed(this.decimals)}<br>` + this.legend[1] + `: ${(dataItem.dataContext.y).toFixed(this.decimals)}`;
        });

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.stroke = am4core.color("#8F3985");
        chart.cursor.lineX.strokeWidth = 4;
        chart.cursor.lineX.strokeOpacity = 0.2;
        chart.cursor.lineX.strokeDasharray = "";

        chart.cursor.lineY.stroke = am4core.color("#8F3985");
        chart.cursor.lineY.strokeWidth = 4;
        chart.cursor.lineY.strokeOpacity = 0.2;
        chart.cursor.lineY.strokeDasharray = "";

        if (this.corners_labels) {
          // Libellé en haut à gauche
          var labelTopLeft = chart.plotContainer.createChild(am4core.Label);
          labelTopLeft.text = this.corners_labels.left_top[0];
          labelTopLeft.fontSize = 14;
          labelTopLeft.fill = this.corners_labels.left_top[1];
          labelTopLeft.align = "left";
          labelTopLeft.valign = "top";
          labelTopLeft.paddingLeft = 10;
          labelTopLeft.paddingTop = 10;
          labelTopLeft.fontWeight = "bold";

          // Libellé en haut à droite
          var labelTopRight = chart.plotContainer.createChild(am4core.Label);
          labelTopRight.text = this.corners_labels.right_top[0];
          labelTopRight.fontSize = 14;
          labelTopRight.fill = this.corners_labels.right_top[1];
          labelTopRight.align = "right";
          labelTopRight.valign = "top";
          labelTopRight.paddingRight = 10;
          labelTopRight.paddingTop = 10;
          labelTopRight.fontWeight = "bold";

          // Libellé en bas à gauche
          var labelBottomLeft = chart.plotContainer.createChild(am4core.Label);
          labelBottomLeft.text = this.corners_labels.left_bottom[0];
          labelBottomLeft.fontSize = 14;
          labelBottomLeft.fill = this.corners_labels.left_bottom[1];
          labelBottomLeft.align = "left";
          labelBottomLeft.valign = "bottom";
          labelBottomLeft.paddingLeft = 10;
          labelBottomLeft.paddingBottom = 10;
          labelBottomLeft.fontWeight = "bold";
          

          // Libellé en bas à droite
          var labelBottomRight = chart.plotContainer.createChild(am4core.Label);
          labelBottomRight.text = this.corners_labels.right_bottom[0];
          labelBottomRight.fontSize = 14;
          labelBottomRight.fill = this.corners_labels.right_bottom[1];
          labelBottomRight.align = "right";
          labelBottomRight.valign = "bottom";
          labelBottomRight.paddingRight = 10;
          labelBottomRight.paddingBottom = 10;
          labelBottomRight.fontWeight = "bold";
        }

      }

      /* ################################################################################################ */
      /* #################### AJOUT DES SERIES AVEC LEURS DONNEES DANS LE GRAPHIQUE ##################### */
      /* ################################################################################################ */

      // Si c'est un radar on ajoute qu'une seule série
      if (this.type == "RadarSeries") {
        chart.data = data_radar_chart;
        chart.series.push(seriesArray[0])
      }
      else if (this.dimensions && this.type == "ColumnSeries" ) {
        // Tri des données en ordre décroissant
        this.sort_tab_histo(data_histo_empile_chart);

        chart.data = data_histo_empile_chart;

        let indice = liens.slice(0, liens.length - 3).length;
        let tab = seriesArray.slice(-indice);
        for (let i = 0; i < tab.length; i++) {
          chart.series.push(tab[i]);
        }
      }
      else if (this.type == "Sorted Bar" ) {
        this.sort_tab_sorted_bar(data_histo_empile_chart);
        chart.data = data_histo_empile_chart;

        chart.series.push(seriesArray[seriesArray.length - 1])
      }
      else if(this.type == "Hybrid ColumnSeries"){
        chart.data = data_histo_empile_chart;
        
        let indice = liens.slice(0, liens.length - 3).length;
        let tab = seriesArray.slice(-indice);
        for (let i = 0; i < tab.length; i++) {
          chart.series.push(tab[i]);
        }
      }
      else {
        // Ajout de toutes les séries dans le graphique
        if (this.type != "HeatMap" && this.type != "BubbleChart" && this.type != "Map" && this.type != "Table" && this.type != "HybridChart") {
          for (let i = 0; i < seriesArray.length; i++) {
            chart.series.push(seriesArray[i]);
          }
        }
      }

      // On enlève le loading sur le graphique
      this.loading = false;

      this.chart = chart;
    },
    updateCountrySelected(country) {
      // Avertit qu'un pays a été cliqué sur la map, cela change les graphiques en conséquences
      const newCountrySelected = country;
      this.$emit('country-selected-changed', newCountrySelected);
    },
    getRandomColor() {
      // Retourne une couleur aléatoire pour le mettre dans un graphique
      // colors contient les 6 couleurs de base qui peut être modifié
      const colors = ["#00A079", "#C00000", "#1D124E", "grey", "#24FF35", "#00D4FF"];
      const randomIndex = Math.floor(Math.random() * colors.length);
      const randomColor = colors[randomIndex];
      return am4core.color(randomColor), randomColor;
    },
    display_infos_chart() {
      this.infos_chart = true;
    },
    close_infos_chart() {
      this.infos_chart = false;
    },
    downloadPNG(chartId) {
      // Télécharge l'image d'un graphique en PNG
      const chart = document.getElementById('chart-' + chartId);

      html2canvas(chart).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = this.series.name + '.png';
        link.click();
      });
    },
    downloadPDF(chartId) {
      // Télécharge l'image d'un graphique en PDF
      const chart = document.getElementById('chart-' + chartId);

      html2canvas(chart).then((canvas) => {

        const imgData = canvas.toDataURL('image/png');

        var pdf;

        if (chart.offsetHeight >= chart.offsetWidth) {
          pdf = new jsPDF()
        } else {
          pdf = new jsPDF({ orientation: 'landscape' })
        }

        var imgWidth = pdf.internal.pageSize.getWidth();
        var imgHeight = pdf.internal.pageSize.getHeight();

        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

        pdf.save(this.series.name + '.pdf');
      });
    },
    exportCSV(data, type) {
      // Télécharge les données d'un graphique en CSV selon le type de graphique
      let csvContent = "data:text/csv;charset=utf-8,";

      if (type == "Radar") {
        csvContent += "Code DataLab,";
        csvContent += "Last Value,";
        csvContent += "\n";

        for (let i = 0; i < data.series.length; i++) {
          csvContent += `${data.series[i]},`;
          csvContent += `${data.value[i]},`;
          csvContent += "\n";
        }
      }
      else if (type == "Category") {
        csvContent += "Category,";

        // Add series headers
        data.series.forEach((series) => {
          csvContent += `${series},`;
        });

        csvContent += "\n";

        // Add data rows
        let value_count = 0;
        for (let i = 0; i < data.category.length; i++) {
          csvContent += `${data.category[i]},`;

          var value = null;
          for (let j = 0; j < data.series.length; j++) {
            value = data.value[value_count];
            csvContent += `${value},`;
            value_count++;
          }

          csvContent += "\n";
        }
      }
      else if (type == "Map") {
        csvContent += "Countries,";

        // Add series headers
        data.series.forEach((series) => {
          csvContent += `${series},`;
        });

        csvContent += "\n";

        // Add data rows
        let value_count = 0;
        for (let i = 0; i < data.category.length; i++) {
          csvContent += `${data.category[i]},`;

          var value2 = null;
          for (let j = 0; j < data.series.length; j++) {
            value2 = data.value[value_count];
            csvContent += `${value2},`;
            value_count++;
          }

          csvContent += "\n";
        }
      }
      else if (type == "Table") {
        csvContent += "Code Datalab,value";
        csvContent += "\n";

        for (let i = 0; i < data.value.length; i++) {
          csvContent += `${data.series[i]},`;
          csvContent += `${data.value[i]},`;
          csvContent += "\n";
        }
      }
      else if (type == "Hybrid Chart") {
        csvContent += "Year,";
        for (let i = 0; i < data.series.length; i++) {
          csvContent += data.series[i] + ",";
        }
        csvContent += "\n";

        let data_value = 0;
        for (let i = 0; i < data.year.length; i++) {
          csvContent += `${data.year[i]},`;
          csvContent += `${data.value[data_value]},`;
          csvContent += `${data.value[data_value + 1]},`;
          data_value += 2;
          csvContent += "\n";
        }
      }
      else {
        csvContent += "Timestamp,";

        if (this.series.legend) {
          data.series.forEach((series) => {
            csvContent += `${series.legend},`;
          });
        } else {
          const code = this.series.codes[0];

          if (code.includes("diff") || code.includes("diff_yoy") || code.includes("growth") || code.includes("growth_yoy")) {
            csvContent += `${this.series.codes[0][0]},`;
          } else {
            csvContent += `${this.series.codes[0]},`;
          }
        }

        csvContent += "\n";

        for (let i = 0; i < data.timestamp.length; i++) {
          csvContent += `${data.timestamp[i]},`;

          data.series.forEach((series) => {
            csvContent += `${series.data[i]},`;
          });

          csvContent += "\n";
        }
      }

      let title_file;
      if (this.graph_title) {
        title_file = this.graph_title
      } else {
        title_file = this.series.name
      }

      if (this.year_map != null) {
        title_file += " in " + this.year_map.substring(0, 4)
      }
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", title_file + ".csv");
      document.body.appendChild(link);

      link.click();
    },
    exportXLSX(data, type) {
      // Télécharge les données d'un graphique en XLSX selon le type de graphique
      const workbook = XLSX.utils.book_new();
      const sheetData = [];

      if (type == "Radar") {
        // Ajout l'en-tête des colonnes
        const headers = ["Code Datalab", "Last Value"];
        sheetData.push(headers);

        // Ajout des données
        for (let i = 0; i < data.series.length; i++) {
          const rowData = [data.series[i], data.value[i]];
          sheetData.push(rowData);
        }
      }
      else if (type == "Category") {

        const headers = ["Category"];
        for (let i = 0; i < data.series.length; i++) {
          headers.push(data.series[i])
        }

        sheetData.push(headers);

        let value_count = 0;

        for (let i = 0; i < data.category.length; i++) {
          const rowData = [data.category[i]];
          for (let j = 0; j < data.series.length; j++) {
            rowData.push(data.value[value_count]);
            value_count++;
          }
          sheetData.push(rowData);
        }
      }
      else if (type == "Map") {
        const headers = ["Countries"];
        for (let i = 0; i < data.series.length; i++) {
          headers.push(data.series[i])
        }

        sheetData.push(headers);

        let value_count = 0;

        for (let i = 0; i < data.category.length; i++) {
          const rowData = [data.category[i]];
          for (let j = 0; j < data.series.length; j++) {
            rowData.push(data.value[value_count]);
            value_count++;
          }
          sheetData.push(rowData);
        }
      }
      else if (type == "Table") {
        const headers = ["Code Datalab", "value"];
        sheetData.push(headers);

        for (let i = 0; i < data.series.length; i++) {
          const rowData = [data.series[i]];
          rowData.push(data.value[i]);
          sheetData.push(rowData);
        }
      }
      else if (type == "Hybrid Chart") {
        const headers = ["Year"]

        for (let i = 0; i < data.series.length; i++) {
          headers.push(data.series[i]);
        }
        sheetData.push(headers);

        let data_value = 0;
        for (let i = 0; i < data.year.length; i++) {
          const rowData = [data.year[i]];
          rowData.push(data.value[data_value]);
          rowData.push(data.value[data_value + 1]);
          sheetData.push(rowData);
          data_value += 2;
        }
      }
      else {
        // Ajout l'en-tête des colonnes
        const headers = ["Timestamp"];

        if (this.series.legend) {
          data.series.forEach((series) => {
            headers.push(series.legend);
          });
        } else {
          headers.push(this.series.codes[0][0]);
        }

        sheetData.push(headers);

        // Ajout des données
        for (let i = 0; i < data.timestamp.length; i++) {
          const rowData = [data.timestamp[i]];
          data.series.forEach((series) => {
            rowData.push(series.data[i]);
          });
          sheetData.push(rowData);
        }
      }

      let title_file;
      if (this.graph_title) {
        title_file = this.graph_title
      } else {
        title_file = this.series.name
      }

      const sheet = XLSX.utils.aoa_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(workbook, sheet, 'Chart Data');

      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      saveAs(blob, title_file + '.xlsx');
    },
    handleKeyDown(event) {
      // Si on appuie sur la touche Escape, cela ferme la popup d'informations d'un graphique
      if (event.key === "Escape") {
        this.close_infos_chart();
      }
    },
    convertIso(iso3, iso2) {
      // Convertit un iso3 en iso2 et vice-versa
      const isoCodes = {
        AFG: "AF",
        ALA: "AX",
        ALB: "AL",
        DZA: "DZ",
        ASM: "AS",
        AND: "AD",
        AGO: "AO",
        AIA: "AI",
        ATA: "AQ",
        ATG: "AG",
        ARG: "AR",
        ARM: "AM",
        ABW: "AW",
        AUS: "AU",
        AUT: "AT",
        AZE: "AZ",
        BHS: "BS",
        BHR: "BH",
        BGD: "BD",
        BRB: "BB",
        BLR: "BY",
        BEL: "BE",
        BLZ: "BZ",
        BEN: "BJ",
        BMU: "BM",
        BTN: "BT",
        BOL: "BO",
        BES: "BQ",
        BIH: "BA",
        BWA: "BW",
        BVT: "BV",
        BRA: "BR",
        IOT: "IO",
        BRN: "BN",
        BGR: "BG",
        BFA: "BF",
        BDI: "BI",
        CPV: "CV",
        KHM: "KH",
        CMR: "CM",
        CAN: "CA",
        CYM: "KY",
        CAF: "CF",
        TCD: "TD",
        CHL: "CL",
        CHN: "CN",
        CXR: "CX",
        CCK: "CC",
        COL: "CO",
        COM: "KM",
        COG: "CG",
        COD: "CD",
        COK: "CK",
        CRI: "CR",
        CIV: "CI",
        HRV: "HR",
        CUB: "CU",
        CUW: "CW",
        CYP: "CY",
        CZE: "CZ",
        DNK: "DK",
        DJI: "DJ",
        DMA: "DM",
        DOM: "DO",
        ECU: "EC",
        EGY: "EG",
        SLV: "SV",
        GNQ: "GQ",
        ERI: "ER",
        EST: "EE",
        SWZ: "SZ",
        ETH: "ET",
        FLK: "FK",
        FRO: "FO",
        FJI: "FJ",
        FIN: "FI",
        FRA: "FR",
        GUF: "GF",
        PYF: "PF",
        ATF: "TF",
        GAB: "GA",
        GMB: "GM",
        GEO: "GE",
        DEU: "DE",
        GHA: "GH",
        GIB: "GI",
        GRC: "GR",
        GRL: "GL",
        GRD: "GD",
        GLP: "GP",
        GUM: "GU",
        GTM: "GT",
        GGY: "GG",
        GIN: "GN",
        GNB: "GW",
        GUY: "GY",
        HTI: "HT",
        HMD: "HM",
        VAT: "VA",
        HND: "HN",
        HKG: "HK",
        HUN: "HU",
        ISL: "IS",
        IND: "IN",
        IDN: "ID",
        IRN: "IR",
        IRQ: "IQ",
        IRL: "IE",
        IMN: "IM",
        ISR: "IL",
        ITA: "IT",
        JAM: "JM",
        JPN: "JP",
        JEY: "JE",
        JOR: "JO",
        KAZ: "KZ",
        KEN: "KE",
        KIR: "KI",
        PRK: "KP",
        KOR: "KR",
        KWT: "KW",
        KGZ: "KG",
        LAO: "LA",
        LVA: "LV",
        LBN: "LB",
        LSO: "LS",
        LBR: "LR",
        LBY: "LY",
        LIE: "LI",
        LTU: "LT",
        LUX: "LU",
        MAC: "MO",
        MKD: "MK",
        MDG: "MG",
        MWI: "MW",
        MYS: "MY",
        MDV: "MV",
        MLI: "ML",
        MLT: "MT",
        MHL: "MH",
        MTQ: "MQ",
        MRT: "MR",
        MUS: "MU",
        MYT: "YT",
        MEX: "MX",
        FSM: "FM",
        MDA: "MD",
        MCO: "MC",
        MNG: "MN",
        MNE: "ME",
        MSR: "MS",
        MAR: "MA",
        MOZ: "MZ",
        MMR: "MM",
        NAM: "NA",
        NRU: "NR",
        NPL: "NP",
        NLD: "NL",
        NCL: "NC",
        NZL: "NZ",
        NIC: "NI",
        NER: "NE",
        NGA: "NG",
        NIU: "NU",
        NFK: "NF",
        MNP: "MP",
        NOR: "NO",
        OMN: "OM",
        PAK: "PK",
        PLW: "PW",
        PSE: "PS",
        PAN: "PA",
        PNG: "PG",
        PRY: "PY",
        PER: "PE",
        PHL: "PH",
        PCN: "PN",
        POL: "PL",
        PRT: "PT",
        PRI: "PR",
        QAT: "QA",
        REU: "RE",
        ROU: "RO",
        RUS: "RU",
        RWA: "RW",
        BLM: "BL",
        SHN: "SH",
        KNA: "KN",
        LCA: "LC",
        MAF: "MF",
        SPM: "PM",
        VCT: "VC",
        WSM: "WS",
        SMR: "SM",
        STP: "ST",
        SAU: "SA",
        SEN: "SN",
        SRB: "RS",
        SYC: "SC",
        SLE: "SL",
        SGP: "SG",
        SXM: "SX",
        SVK: "SK",
        SVN: "SI",
        SLB: "SB",
        SOM: "SO",
        ZAF: "ZA",
        SGS: "GS",
        SSD: "SS",
        ESP: "ES",
        LKA: "LK",
        SDN: "SD",
        SUR: "SR",
        SJM: "SJ",
        SWE: "SE",
        CHE: "CH",
        SYR: "SY",
        TWN: "TW",
        TJK: "TJ",
        TZA: "TZ",
        THA: "TH",
        TLS: "TL",
        TGO: "TG",
        TKL: "TK",
        TON: "TO",
        TTO: "TT",
        TUN: "TN",
        TUR: "TR",
        TKM: "TM",
        TCA: "TC",
        TUV: "TV",
        UGA: "UG",
        UKR: "UA",
        ARE: "AE",
        GBR: "GB",
        USA: "US",
        UMI: "UM",
        URY: "UY",
        UZB: "UZ",
        VUT: "VU",
        VEN: "VE",
        VNM: "VN",
        VGB: "VG",
        VIR: "VI",
        WLF: "WF",
        ESH: "EH",
        YEM: "YE",
        ZMB: "ZM",
        ZWE: "ZW"
      };

      let res = null

      if (iso3 != null) {
        res = isoCodes[iso3] || "";
      }
      else {
        for (let iso3 in isoCodes) {
          if (Object.prototype.hasOwnProperty.call(isoCodes, iso3) && isoCodes[iso3] === iso2) {
            res = iso3;
          }
        }
      }

      return res;
    }
  },
  mounted() {
    this.renderChart();
    document.documentElement.addEventListener("keydown", this.handleKeyDown);
  },
  beforeUmount() {
    if (this.chart) {
      this.chart.dispose();
    }
    document.documentElement.removeEventListener("keydown", this.handleKeyDown);
  },
  created() {
    api.get("/user/me")
      .then(response => {
        this.user = response.data;
      })
    this.series = {
      datas: [],
    }
  }
}


</script>


<style scoped>
.chart {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  height: 550px;
}

.card_sorted_bar {
  height: 800px;
}

.title {
  font-size: 1.3em;
  font-weight: 600;
}

.subtitle {
  font-size: 1.1em;
}

.source {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.src-txt {
  font-weight: 600;
}

.menu-button {
  cursor: pointer;
  position: absolute;
  top: 1em;
  right: 1.2em;
}

.fa-bars {
  font-size: 1.5em;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.9em;
  height: 1.9em;
  border-radius: 50%;
  position: absolute;
  top: 0.4em;
  right: 0.6em;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.close2 {
  width: 2.1em;
  height: 2.1em;
}

.close:hover {
  background-color: rgba(30, 28, 28, 0.115);
}

.close:active {
  background-color: rgba(30, 28, 28, 0.265);
}

.fa-xmark {
  font-size: 1.7em;
}

.infos_chart {
  position: fixed;
  top: 13vh;
  left: 15%;
  width: 70%;
  padding: 1em;
  border-radius: 20px;
  z-index: 100;
  background-color: white;
  box-shadow: 0 0 5px black;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.opacity_background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.382);
  z-index: 99;
}

.infos_chart h2 {
  font-weight: 600;
  margin: 1em;
}

.infos_chart h2,
.infos_chart h3 {
  text-align: center;
}

.fade-in {
  opacity: 1;
}

.infos_chart table {
  width: 80%;
  margin: 0.5em;
}

.infos_chart table td,
.infos_chart table th {
  border: 1px solid #333;
  padding: 0.5em;
  text-align: center;
}

.infos_chart button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0.4em;
  background-color: rgb(233, 233, 233);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  width: 190px;
  margin: 0.5em;
  font-size: 0.9em;
}

.infos_chart button:hover {
  background-color: rgb(218, 217, 217);
}

.buttons_infos {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.buttons_infos i {
  font-size: 1.1em;
  margin-left: 0.3em;
}

.buttons_disabled {
  font-size: 0.9em;
}

.custom-legend {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend-icon {
  width: 20px;
  height: 20px;
  background-color: rgb(189, 189, 189);
  margin-right: 0.5em;
  border-radius: 4px;
}

.loader {
  position: absolute;
  top: 40%;
  left: 47%;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #021A6F;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: 1;
}

.chart {
  position: relative;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width:1400px) {
  .card {
    height: 700px;
  }
}

@media screen and (max-width:1000px) {
  .infos_chart {
    top: 5%;
    left: 5%;
    width: 90%;
  }

  .infos_chart table td {
    font-size: 0.8em;
  }
}
</style>