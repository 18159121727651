<template>
    <div class="content">
      <div class="container">
        <div class="table-responsive">
          <table class="table custom-table">
            <thead>
              <tr>
                <th v-for="header in headers" :key="header.value" class="headers" 
                    :class="{'name-column': header.value === 'name'}" 
                    scope="col" 
                    @click="header.sortable ? $emit('sort', header.value) : null"
                    :style="header.sortable ? { cursor: 'pointer' } : null">
                  {{ header.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" class="items" :key="item.id">
                <td style="cursor: pointer; font-weight: bold;" @click="name(item.url)" class="name-column">{{ item.name }}</td>
                <td>{{ item.country_short_name }}</td>
                <td>{{ item.dataset_name }}</td>
                <td>{{ item.provider_name }}</td>
                <td>{{ item.min_date }}</td>
                <td>{{ item.max_date }}</td>
                <td>{{ item.frequency }}</td>
                <td>{{ item.unit }}</td>
                <td>
                  <div class="badge" :class="item.free ? 'badge-free' : 'badge-premium'">
                    {{ item.free ? 'Free' : 'Premium' }}
                  </div>
                </td>
                <td style="text-align: center;">
                    <i class="fa-solid fa-file fa-2x" style="cursor: pointer;" :data-bs-target="'#modal' + index" data-bs-toggle="modal"></i>
                </td>
              </tr>

              <tr v-for="(item, index) in items" :key="'modal-row-' + index">
                <div :id="'modal' + index" class="modal fade" tabindex="-1" role="dialog">
                  <div class="modal-dialog modal-dialog-right" role="document">
                    <div class="modal-content">
                      <div class="modal-header" style="align-items: center;">
                        <div class="d-flex" style="align-items: center;">
                            <h5 style="font-weight: normal; font-size: 18px; margin-right: 20px; margin-top: 5px">Dataset Preview</h5>
                            <div class="badge" :class="item.free ? 'badge-free' : 'badge-premium'">
                                {{ item.free ? 'Free' : 'Premium' }}
                            </div>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <span data-bs-dismiss="modal" style="margin-right: 20px; padding: 5px 5px 7px 7px; border: 1px solid black; border-radius: 5px; cursor: pointer;" @click="name(item.url)">view full details</span>
                            <i class="fa-solid fa-x" style="cursor: pointer;" data-bs-dismiss="modal" aria-label="Close"></i>
                        </div>    
                      </div>
                      <div class="modal-body">
                        <span>Dataset Details</span>
                        <div style="margin-top: 10px;">
                            <div style="width: 100%; display: flex;">
                                <div style="width: 100%; display: flex; justify-content: space-between; border-bottom: 1px solid rgba(232, 232, 232, 0.75);">
                                    <p>Name</p>
                                    <span style="margin-left: 10px; font-weight: bold;">{{item.name}}</span>
                                </div>
                            </div>
                            <div style="width: 100%; display: flex; margin-top: 10px; font-size: 12px;">
                                <div style="margin-right: 20px; width: 50%; display: flex; justify-content: space-between; border-bottom: 1px solid rgba(232, 232, 232, 0.75);">
                                    <p>Dataset</p>
                                    <span>{{item.dataset_name}}</span>
                                </div>
                                <div style="margin-right: 20px; width: 50%; display: flex; justify-content: space-between; border-bottom: 1px solid rgba(232, 232, 232, 0.75);">
                                    <p>Country</p>
                                    <span>{{ item.country_short_name }}</span>
                                </div>
                            </div>
                            <div style="width: 100%; display: flex; margin-top: 10px; font-size: 12px;">
                                <div style="margin-right: 20px; width: 50%; display: flex; justify-content: space-between; border-bottom: 1px solid rgba(232, 232, 232, 0.75);">
                                    <p>Api Code</p>
                                    <span>{{item.id}}</span>
                                </div>
                                <div style="margin-right: 20px; width: 50%; display: flex; justify-content: space-between; border-bottom: 1px solid rgba(232, 232, 232, 0.75);">
                                    <p>Unit</p>
                                    <span>{{ item.unit }}</span>
                                </div>
                            </div>
                            <ChartPreview :chart-id="`chart-${index}`" :url="item.id" style="margin-top: 20px;" />
                            <div style="width: 100%; display: flex; margin-top: 10px;">
                                <div style="width: 100%; border-bottom: 1px solid rgba(232, 232, 232, 0.75); padding-bottom: 10px;">
                                    <div style="display: flex;">
                                        <p>Description</p>
                                    </div>
                                    <div style="display: flex; justify-content: start; text-align: left;">
                                        <span style="margin-left: 10px;">{{item.dataset_description}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import ChartPreview from "@/components/ChartPreview.vue";

  export default {
    components: {
      ChartPreview,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        headers: [
          { text: "NAME", value: "name", sortable: true },
          { text: "COUNTRY", value: "country_short_name", sortable: true },
          { text: "DATASET", value: "dataset_name", sortable: true },
          { text: "PROVIDER", value: "provider_name", sortable: true },
          { text: "START DATE", value: "min_date", sortable: true },
          { text: "END DATE", value: "max_date", sortable: true },
          { text: "FREQUENCY", value: "frequency", sortable: true },
          { text: "UNIT", value: "unit", sortable: true },
          { text: "FREE", value: "free", sortable: false },
          { text: "PREVIEW", value: "preview", sortable: false },
        ],
        isActive: [],
        activeIndex: null,
      };
    },
    methods: {
      toggleActive(index) {
        this.isActive[index] = !this.isActive[index];
      },
      sort(value) {
        return value
      },
      async name(route) {
        this.$router.push(route)
        },
    },
    mounted() {
      this.isActive = new Array(this.items.length).fill(false);
    },
  };
  </script>
  

<style scoped>

p {
  color: #b3b3b3;
  font-weight: 300; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a, a:hover {
    text-decoration: none !important; }

.content {
  padding: 0rem 0; }

h2 {
  font-size: 20px; }

.headers {
  font-size: 11px; }

.items {
    font-size: 10px;
}

.custom-table {
  min-width: 900px; }
  .custom-table thead tr, .custom-table thead th {
    border-top: none;
    border-bottom: none !important; }
  .custom-table tbody th, .custom-table tbody td {
    color: #777;
    font-weight: 400;
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 300; }
    .custom-table tbody th small, .custom-table tbody td small {
      color: #b3b3b3;
      font-weight: 300; }
  .custom-table tbody .persons {
    padding: 0;
    margin: 0; }
    .custom-table tbody .persons li {
      padding: 0;
      margin: 0 0 0 -15px;
      list-style: none;
      display: inline-block; }
      .custom-table tbody .persons li a {
        display: inline-block;
        width: 36px; }
        .custom-table tbody .persons li a img {
          border-radius: 50%;
          max-width: 100%; }
  .custom-table tbody tr th, .custom-table tbody tr td {
    position: relative;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
    .custom-table tbody tr th:before, .custom-table tbody tr th:after, .custom-table tbody tr td:before, .custom-table tbody tr td:after {
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease;
      content: "";
      left: 0;
      right: 0;
      position: absolute;
      height: 1px;
      background: #bfbfbf;
      width: 100%;
      opacity: 0;
      visibility: hidden; }
    .custom-table tbody tr th:before, .custom-table tbody tr td:before {
      top: -1px; }
    .custom-table tbody tr th:after, .custom-table tbody tr td:after {
      bottom: -1px; }
  .custom-table tbody tr:hover th, .custom-table tbody tr:hover td {
    background: rgba(0, 0, 0, 0.03); }
    .custom-table tbody tr:hover th:before, .custom-table tbody tr:hover th:after, .custom-table tbody tr:hover td:before, .custom-table tbody tr:hover td:after {
      opacity: 1;
      visibility: visible; }
  .custom-table tbody tr.active th, .custom-table tbody tr.active td {
    background: rgba(0, 0, 0, 0.03); }
    .custom-table tbody tr.active th:before, .custom-table tbody tr.active th:after, .custom-table tbody tr.active td:before, .custom-table tbody tr.active td:after {
      opacity: 1;
      visibility: visible; }

.name-column {
  width: 300px;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.control {
  display: block;
  position: relative;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 18px; }

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 2px solid #ccc;
  background: transparent; }

.control--radio .control__indicator {
  border-radius: 50%; }

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  border: 2px solid #007bff; }

.control input:checked ~ .control__indicator {
  border: 2px solid #007bff;
  background: #007bff; }

.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
  border: 2px solid #ccc; }

.control__indicator:after {
  font-family: 'icomoon';
  content: '\e5ca';
  position: absolute;
  display: none; }

.control input:checked ~ .control__indicator:after {
  display: block;
  color: #fff; }

.control--checkbox .control__indicator:after {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -52%);
  -ms-transform: translate(-50%, -52%);
  transform: translate(-50%, -52%); }

.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b; }

.control--checkbox input:disabled:checked ~ .control__indicator {
  background-color: #007bff;
  opacity: .2;
  border: 2px solid #007bff; }

</style>